import { useApolloClient } from '@apollo/client';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { every, merge, noop, pick, some, startsWith } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formToInputAddress } from '../../../app/data/locationConversions';
import { NewShipmentDateSpecialValue } from '../../../app/data/newShipmentConversions';
import { CURRENT_TIME_QUERY } from '../../../app/graphql/geographicQueries';
import { fromServerDateTime } from '../../../common/utils/dateUtils';
import { useDialogControls } from '../../../common/utils/dialogUtils';
import { useCachedProp } from '../../../common/utils/hookUtils';
import Whitespace from '../../../components/Whitespace';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import { SimpleConfirmDialog } from '../../../components/dialogs/SimpleConfirmDialog';
import { FormItemFieldRegistration } from '../../../components/forms/basicFormElements';
import { FormItemDateTimePicker } from '../../../components/forms/dateElements';
import { useDynamicFormSchemaFieldDefinition } from '../../../components/forms/dynamic/dynamicFormSchema';
import { useDynamicFormValidationRules } from '../../../components/forms/dynamic/dynamicFormValidation';
import {
  isFormValuePresent,
  useValidationAfterChange,
} from '../../../components/forms/formHelpers';
import { useFormContext } from '../../../components/forms/forms';
import { nextEventPromise } from '../../../utils/htmlUtils';
import { SavedShipmentContext } from '../newShipment/SavedShipmentProvider';
import { JobLocationSection } from '../newShipment/newShipmentElements';

const getSpecialValueNames = name => ({
  flag: `${name}-specialValue`,
  data: `${name}-specialValueData`,
});

function useDateTimeSpecialValue({ name }) {
  const { values, formInstance, forceUpdate } = useFormContext();

  const {
    flag: specialValueName,
    data: specialValueDataName,
  } = getSpecialValueNames(name);

  // Validation rules change depending on the value, so it needs to be rerun on change
  useValidationAfterChange({
    dependencyName: specialValueName,
    dependentNames: [name, `${name}-date`, `${name}-time`],
  });

  const date = values[`${name}-date`]?.toISOString();
  const specialValue = values[specialValueName];

  useEffect(() => {
    if (date && specialValue) {
      // Disabling special value when a date is selected
      formInstance.setFieldsValue({
        [specialValueName]: null,
        [specialValueDataName]: null,
      });
      forceUpdate();
    }
  }, [
    date,
    forceUpdate,
    formInstance,
    specialValue,
    specialValueDataName,
    specialValueName,
  ]);

  return {
    specialValue,
    setSpecialValue: value => {
      formInstance.setFieldsValue({
        [specialValueName]: value,
        [specialValueDataName]: null,
        [name]: null,
        [`${name}-date`]: null,
        [`${name}-time`]: null,
      });
      forceUpdate();
    },
  };
}

function computePropsFromDateTimeSpecialValue(
  value,
  { rules, specialRules, datePickerProps, intl }
) {
  if (value) {
    let placeholder = '';

    if (value === NewShipmentDateSpecialValue.NOW) {
      placeholder = intl.formatMessage({
        id: 'book.newShipment.label.dateTime.now',
      });
    }
    if (value === NewShipmentDateSpecialValue.ASAP) {
      placeholder = intl.formatMessage({ id: 'date.asap' });
    }
    if (value === NewShipmentDateSpecialValue.TBD) {
      placeholder = intl.formatMessage({ id: 'labels.tbd.saveShipment' });
    }

    return {
      rules: [{ required: false }, ...specialRules],
      timeProps: { disabled: true },
      dateProps: {
        formItemComponentProps: {
          className: classNames(
            datePickerProps.dateProps.formItemComponentProps.className,
            'ant-picker--special-value'
          ),
          placeholder,
        },
      },
    };
  }
  return { rules };
}

const NOW_DEPENDENCIES_FORM = [
  ['address', 'country'],
  ['address', 'stateProvince'],
  ['address', 'city'],
];
const NOW_DEPENDENCIES_VALIDATION = ['country', 'stateProvince', 'city'];

const DEFAULT_GET_ADDRESS = ({ formValues }) => formValues.address;
function useNowValidator({
  name,
  getAddress = DEFAULT_GET_ADDRESS,
  onError: onErrorOuter = noop,
}) {
  const { formInstance, forceUpdate, submittingRef } = useFormContext();
  const client = useApolloClient();
  const {
    flag: specialValueName,
    data: specialValueDataName,
  } = getSpecialValueNames(name);

  const [nowAvailable, setNowAvailable] = useState(true);

  const getCompactAddress = vals =>
    pick(getAddress({ formValues: vals }), NOW_DEPENDENCIES_VALIDATION);
  const deps = useCachedProp(getCompactAddress(formInstance.getFieldsValue()));
  useEffect(() => {
    setNowAvailable(true);
  }, [deps]);

  const onError = () => {
    setNowAvailable(false);
    formInstance.setFieldsValue({
      [specialValueName]: null,
      [specialValueDataName]: null,
    });
    forceUpdate();
    onErrorOuter();
  };

  const rule = {
    validator: async () => {
      if (
        formInstance.getFieldValue(specialValueName) !==
        NewShipmentDateSpecialValue.NOW
      ) {
        return Promise.resolve();
      }

      // Wait for editing of dependencies to be finished
      const { activeElement } = document;
      if (
        activeElement &&
        some(NOW_DEPENDENCIES_FORM, dep =>
          startsWith(activeElement.id, dep.join(','))
        )
      ) {
        await nextEventPromise(activeElement, 'blur');
      }

      const addr = getCompactAddress(formInstance.getFieldsValue());

      // Skip if one of the dependencies is empty (unless submitting, then we will check anyway)
      if (
        !every(NOW_DEPENDENCIES_VALIDATION, fieldName =>
          isFormValuePresent(addr[fieldName])
        ) &&
        !submittingRef.current
      ) {
        return Promise.resolve();
      }

      try {
        const {
          data: { currentTime },
        } = await client.query({
          query: CURRENT_TIME_QUERY,
          variables: {
            address: formToInputAddress(addr),
          },
          fetchPolicy: 'network-only',
        });
        if (!currentTime) {
          onError();
          return Promise.reject();
        }

        formInstance.setFieldsValue({
          [specialValueDataName]: fromServerDateTime(currentTime),
        });
        forceUpdate();
        return Promise.resolve();
      } catch (e) {
        onError();
        return Promise.reject();
      }
    },
  };

  return { rule, nowAvailable };
}

function DateTimeSpecialValueButton({ onClick, textId }) {
  return (
    <div className="DatePicker-FooterButton" onClick={onClick}>
      <FormattedMessage id={textId} />
    </div>
  );
}

function DateTimePickerRow({
  name,
  schemaName,
  dateLabelId,
  timeLabelId = 'book.newShipment.label.time',
  timeTooltipId = 'book.newShipment.label.time.tooltip',
  showNow,
  showAsap,
  showTbd,
  requiredInLabel,
  getAddress,
}) {
  const intl = useIntl();
  const { specialValue, setSpecialValue } = useDateTimeSpecialValue({ name });

  const fieldDef = useDynamicFormSchemaFieldDefinition({
    schemaName,
  });
  const rules = useDynamicFormValidationRules({
    schemaName,
    validation: fieldDef.validation,
  });

  const {
    flag: specialValueName,
    data: specialValueDataName,
  } = getSpecialValueNames(name);

  const nowErrorDialog = useDialogControls();
  const { rule: nowRule, nowAvailable } = useNowValidator({
    name,
    onError: nowErrorDialog.open,
    getAddress,
  });
  // Ant's `dependencies` are not triggering validation after the programmatic changes,
  // which is insufficient for our scenario with lot of autofills
  useValidationAfterChange({
    dependenciesNames: showNow ? NOW_DEPENDENCIES_FORM : [],
    dependentNames: [name],
  });

  const pickerPropsStatic = {
    dateProps: {
      formItemComponentProps: {
        renderExtraFooter: ({ setPickerOpen }) => (
          <>
            {showNow && nowAvailable && (
              <DateTimeSpecialValueButton
                textId="date.now"
                onClick={() => {
                  setSpecialValue(NewShipmentDateSpecialValue.NOW);
                  setPickerOpen(false);
                }}
              />
            )}
            {showAsap && (
              <DateTimeSpecialValueButton
                textId="date.asap"
                onClick={() => {
                  setSpecialValue(NewShipmentDateSpecialValue.ASAP);
                  setPickerOpen(false);
                }}
              />
            )}
            {showTbd && (
              <DateTimeSpecialValueButton
                textId="labels.tbd.saveShipment"
                onClick={() => {
                  setSpecialValue(NewShipmentDateSpecialValue.TBD);
                  setPickerOpen(false);
                }}
              />
            )}
          </>
        ),
      },
      showNow: false,
    },
  };
  const pickerProps = merge(
    {},
    pickerPropsStatic,
    computePropsFromDateTimeSpecialValue(specialValue, {
      rules,
      specialRules: showNow ? [nowRule] : [],
      datePickerProps: pickerPropsStatic,
      intl,
    })
  );

  return (
    <JobLocationSection
      title={
        <div className="DateTimePicker expand-size">
          <div className="Col-DatePicker">
            <FormattedMessage id={dateLabelId} />
            {requiredInLabel && ' *'}
          </div>
          <div className="Col-TimePicker text-no-transform">
            <FormattedMessage id={timeLabelId} />
            {requiredInLabel && ' *'}
            {timeTooltipId && (
              <>
                <Whitespace />
                <Tooltip
                  title={<FormattedMessage id={timeTooltipId} />}
                  overlayClassName="ant-tooltip-custom-lines"
                >
                  <FAIcon
                    icon="question-circle"
                    className="color-highlight icon-12"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      }
      titleClassName="Flex1"
    >
      <div>
        <FormItemDateTimePicker
          name={name}
          rules={rules}
          expandSize
          {...pickerProps}
        />
        <FormItemFieldRegistration name={specialValueName} />
        <FormItemFieldRegistration name={specialValueDataName} />
        <SimpleConfirmDialog
          visible={nowErrorDialog.isOpen}
          onClose={nowErrorDialog.close}
          cancelTextId="buttons.close"
          textId="book.newShipment.label.dateTime.now.notAvailable"
        />
      </div>
    </JobLocationSection>
  );
}

const PICKUP_DT_NAME = 'pickupDateTime';
export function PickupDateTimeField({
  labelId = 'book.newShipment.label.pickupDateTime',
  showRequiredInLabel,
  getAddress,
}) {
  const fieldDef = useDynamicFormSchemaFieldDefinition({
    schemaName: PICKUP_DT_NAME,
  });
  const { enabled: savedShipmentEnabled } = useContext(SavedShipmentContext);

  return (
    <DateTimePickerRow
      name={PICKUP_DT_NAME}
      schemaName={PICKUP_DT_NAME}
      dateLabelId={labelId}
      showNow
      showTbd={savedShipmentEnabled}
      requiredInLabel={
        showRequiredInLabel && fieldDef.validation.rules.required
      }
      getAddress={getAddress}
    />
  );
}

const DELIVERY_DT_NAME = 'deliveryDateTime';
export function DeliveryDateTimeField({
  labelId = 'book.newShipment.label.deliveryDateTime',
  showRequiredInLabel,
}) {
  const fieldDef = useDynamicFormSchemaFieldDefinition({
    schemaName: PICKUP_DT_NAME,
  });
  const { enabled: savedShipmentEnabled } = useContext(SavedShipmentContext);

  return (
    <DateTimePickerRow
      name={DELIVERY_DT_NAME}
      schemaName={DELIVERY_DT_NAME}
      dateLabelId={labelId}
      showNow={false}
      showAsap
      showTbd={savedShipmentEnabled}
      requiredInLabel={
        showRequiredInLabel && fieldDef.validation.rules.required
      }
    />
  );
}
