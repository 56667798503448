import { compact, groupBy, isEmpty, property } from 'lodash-es';

import { useAccounts } from '../../hooks/data/auth';
import { ReportsSelectionSection } from './ReportsSelectionSection';

const SPLIT_INTO_GROUPS = items => {
  const {
    true: partnerAccounts = [],
    false: nonPartnerAccounts = [],
  } = groupBy(items, acc => acc.partner);

  return compact([
    !isEmpty(partnerAccounts) && {
      key: 'partner',
      labelId: 'reports.accountSelection.partnerAccess',
      classNames: 'ReportsDialog__SelectionSection__GroupLabel--Partner',
      items: partnerAccounts,
    },
    !isEmpty(nonPartnerAccounts) && {
      key: 'non-partner',
      labelId: 'reports.accountSelection.accounts',
      classNames: 'ReportsDialog__SelectionSection__GroupLabel--NonPartner',
      items: nonPartnerAccounts,
    },
  ]);
};

const COLUMNS = [
  {
    key: 'number',
    labelId: 'reports.accountSelection.accountNumber',
    span: 8,
    render: acc => acc.number,
  },
  {
    key: 'name',
    labelId: 'reports.accountSelection.customerName',
    span: 16,
    render: acc => acc.name,
  },
];

export function ReportsAccountSelection() {
  const { customerAccounts, error, loading } = useAccounts();

  return (
    <ReportsSelectionSection
      index={1}
      titleId="reports.chooseAccounts"
      headerId="reports.accountSelection"
      subtitleId="reports.accountSelection.subtitle"
      searchPlaceholderId="reports.accountSearch.placeholder"
      items={customerAccounts}
      loading={loading}
      error={error}
      getSearchSourcesFromItem={acc => [acc.name, acc.number]}
      splitIntoGroups={SPLIT_INTO_GROUPS}
      fieldName="selectedAccounts"
      getKey={property('number')}
      getLabel={property('number')}
      columns={COLUMNS}
    />
  );
}
