import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { DOCUMENT_GROUPS_QUERY } from '../../../../app/graphql/adminQueries';
import { extractGraphqlEntity } from '../../../../common/utils/graphqlUtils';
import { renderDataStateIndicator } from '../../../../components/data/dataStateHandlers';
import { FormItemFieldRegistration } from '../../../../components/forms/basicFormElements';
import { FormItemRadioGroup } from '../../../../components/forms/checkable';
import { useFormContext } from '../../../../components/forms/forms';
import { useObjectValues } from '../../../../components/forms/selectDecorators';
import { codeValueToRichSelectOption } from '../../../../components/forms/selectUtils';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';

function useDefaultOption({ options }) {
  const { values, forceUpdate, formInstance } = useFormContext();
  const val = values.documentGroup;
  useEffect(() => {
    if (!val?.code && !isEmpty(options)) {
      if (options.length === 1) {
        formInstance.setFieldsValue({ documentGroup: options[0].value });
      } else {
        const custOption = options.find(
          opt => opt.value?.code === 'CUSTOMER' || /customer/i.test(opt.label)
        );
        formInstance.setFieldsValue({
          documentGroup: custOption?.value || options[0].value,
        });
      }
      forceUpdate();
    }
  }, [forceUpdate, formInstance, options, val]);
}

export default function AdminUserDocumentGroupFormSection() {
  const { data, loading, error } = useQuery(DOCUMENT_GROUPS_QUERY);
  const indicator = renderDataStateIndicator({ data, loading, error });
  const richOptions = ((data && extractGraphqlEntity(data)) || []).map(
    codeValueToRichSelectOption
  );
  const { options: simpleOptions, formatValue, parseValue } = useObjectValues({
    options: richOptions,
    optionKey: 'code',
  });
  useDefaultOption({ options: richOptions });

  return (
    <AdminConsoleSection>
      <AdminConsoleSectionTitleHeader>
        <strong>
          <FormattedMessage id="admin.userManagement.forms.allowedDocumentGroups" />
        </strong>
      </AdminConsoleSectionTitleHeader>
      <AdminConsoleSectionContent>
        <FormItemFieldRegistration name="documentGroup" />
        {indicator || (
          <FormItemRadioGroup
            name="documentGroup"
            className="AdminConsole__ChoiceSelect"
            options={simpleOptions}
            formatValue={formatValue}
            parseValue={parseValue}
            layout="vertical"
            rules={[{ required: true }]}
          />
        )}
      </AdminConsoleSectionContent>
    </AdminConsoleSection>
  );
}
