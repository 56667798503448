import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  ACCEPT_TERMS_OF_SERVICE_MUTATION,
  LOGOUT_MUTATION,
} from '../app/graphql/authQueries';
import { loginUser, prepareReleaseNotes } from '../app/redux/auth';
import routes from '../app/routes';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import { FlexCol } from '../components/layout/layoutElements';
import LoginForm from '../forms/LoginForm';
import NonLoggedPage from '../templates/NonLoggedPage';
import {
  AlertText,
  ServiceAlertsHeader,
  useServiceAlertsState,
} from '../widgets/news';
import TermsOfServicePage from './TermsOfServicePage';

const MAINTENANCE_ALERT =
  'Please be informed that our system will undergo scheduled maintenance from 11 pm EST on Saturday, March 2nd to 3 am EST on Sunday, March 3rd. QuickOnline will be unavailable during this period, so we request your cooperation in planning accordingly and apologize for any inconvenience this may cause.';

export default function LoginPage() {
  const serviceAlertsState = useServiceAlertsState();

  const [termsOfService, setTermsOfService] = useState();
  const [loginResult, setLoginResult] = useState();
  const onTermsOfService = useCallback(({ termsOfService: tos, ...lr }) => {
    setLoginResult(lr);
    setTermsOfService(tos);
  }, []);

  const dispatch = useDispatch();
  const { push } = useHistory();
  const onLoginSuccess = useCallback(
    ({ email, token, releaseNotes }) => {
      dispatch(loginUser(email, token));
      if (releaseNotes) {
        dispatch(prepareReleaseNotes(releaseNotes));
      }
      push(routes.root);
    },
    [dispatch, push]
  );

  const { errorMessage } = useFlashMessageContext();
  const [acceptTos] = useMutation(ACCEPT_TERMS_OF_SERVICE_MUTATION, {
    context: { headers: { 'auth-token': loginResult?.token } },
  });
  const [logout] = useMutation(LOGOUT_MUTATION, {
    context: { headers: { 'auth-token': loginResult?.token } },
  });
  const onTosAccept = useCallback(async () => {
    try {
      await acceptTos();
      // These will cause unmounting, so we want to wait until all operations are completed
      window.requestAnimationFrame(() => {
        onLoginSuccess(loginResult);
      });
    } catch (e) {
      errorMessage(e);
    }
  }, [acceptTos, errorMessage, loginResult, onLoginSuccess]);

  const onTosCancel = useCallback(async () => {
    try {
      await logout();
      setTermsOfService(undefined);
      setLoginResult(undefined);
    } catch (e) {
      errorMessage(e);
    }
  }, [errorMessage, logout]);

  if (termsOfService) {
    return (
      <TermsOfServicePage
        content={termsOfService}
        onAccept={onTosAccept}
        onCancel={onTosCancel}
      />
    );
  }

  return (
    <NonLoggedPage id="LoginPage">
      <ServiceAlertsHeader
        className="hide-xl-and-bigger hide-sm-and-smaller"
        {...serviceAlertsState}
      />
      <div className="NonLoggedPage-Columns">
        <FlexCol className="column-wide FlexAlignStretch ServiceAlerts-Container">
          <AlertText
            alert={{
              description: MAINTENANCE_ALERT,
            }}
          />
        </FlexCol>
        <LoginForm
          onLoginSuccess={onLoginSuccess}
          onTermsOfService={onTermsOfService}
        />
      </div>
    </NonLoggedPage>
  );
}
