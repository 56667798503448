/* eslint-disable react/style-prop-object */
import { isNumber } from 'lodash-es';
import { FormattedNumber } from 'react-intl';

import { FAIcon } from '../adapters/fontAwesomeAdapters';
import { FormItemInputText } from '../forms/basicFormElements';

// TODO - bind to config

export function FormItemInputCurrency({ formItemComponentProps, ...rest }) {
  return (
    <FormItemInputText
      formItemComponentProps={{
        ...formItemComponentProps,
        prefix: <FAIcon icon="dollar-sign" />,
      }}
      {...rest}
    />
  );
}

export function CurrencyFormat({ value, ...rest }) {
  if (!isNumber(value)) {
    return '-';
  }
  return (
    <FormattedNumber
      style="currency"
      currency="USD"
      maximumFractionDigits={2}
      minimumFractionDigits={0}
      value={value}
      {...rest}
    />
  );
}
