import { noop } from 'lodash-es';
import { useCallback, useState } from 'react';

import { SimpleConfirmDialog } from '../../components/dialogs/SimpleConfirmDialog';

export const PageExitDialogType = {
  CANCEL_SHIPMENT: 'CANCEL_SHIPMENT',
  CANCEL_EDIT_SHIPMENT: 'CANCEL_EDIT_SHIPMENT',
};

const NO_DIALOG_OPEN = { type: undefined, callback: noop };

export function usePageExitDialogState() {
  const [{ type, callback }, setState] = useState({ ...NO_DIALOG_OPEN });
  const close = () => setState({ ...NO_DIALOG_OPEN });
  const getUserConfirmation = useCallback(
    (dialogType, cb) => {
      setState({ type: dialogType, callback: cb });
    },
    [setState]
  );
  return [
    {
      type,
      onOk: () => {
        close();
        callback(true);
      },
      onClose: () => {
        close();
        callback(false);
      },
    },
    getUserConfirmation,
  ];
}

function CancelShipmentDialog({ isOpen, onOk, onClose }) {
  return (
    <SimpleConfirmDialog
      cancelTextId="buttons.close"
      okTextId="book.newShipment.cancelShipment.dialog.okButton"
      onOk={onOk}
      onClose={onClose}
      textId="book.newShipment.cancelShipment.dialog.text"
      visible={isOpen}
    />
  );
}

function CancelEditShipmentDialog({ isOpen, onOk, onClose }) {
  return (
    <SimpleConfirmDialog
      cancelTextId="buttons.close"
      okTextId="book.editShipment.cancelShipment.dialog.okButton"
      onOk={onOk}
      onClose={onClose}
      textId="book.editShipment.cancelShipment.dialog.text"
      visible={isOpen}
    />
  );
}

export default function PageExitDialog({ type, onOk, onClose }) {
  const isCancelShipmentOpen = type === PageExitDialogType.CANCEL_SHIPMENT;
  const isCancelEditShipmentOpen =
    type === PageExitDialogType.CANCEL_EDIT_SHIPMENT;

  return (
    <>
      {/* All dialogs need to be rendered for animation purposes - `isOpen` prop will be used for controlling them */}
      <CancelShipmentDialog
        isOpen={isCancelShipmentOpen}
        onOk={onOk}
        onClose={onClose}
      />
      <CancelEditShipmentDialog
        isOpen={isCancelEditShipmentOpen}
        onOk={onOk}
        onClose={onClose}
      />
    </>
  );
}
