import { useApolloClient, useQuery } from '@apollo/client';
import { isEqual, isNumber, noop } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import {
  useCachedProp,
  useMountAndUpdateEffect,
} from '../../common/utils/hookUtils';
import { DEFAULT_PAGE_SIZE, PAGE_ONE } from '../../config/constants';
import { formatErrorWithMessage } from '../../utils/errorUtils';
import { useMountedIndicator, useStateIfMounted } from '../../utils/reactUtils';
import { useFlashMessageContext } from '../dialogs/FlashMessageProvider';

const PAGE_ONE_VARS = {
  pageIndex: 1,
  pageSize: DEFAULT_PAGE_SIZE,
};

export function useClearCacheOnVariablesChange({
  query,
  variables,
  onReset = noop,
  graphqlEntityName,
  graphqlResultTypename,
}) {
  const client = useApolloClient();
  const executeClearCache = () =>
    client.cache.writeQuery({
      query,
      variables: { ...variables, ...PAGE_ONE_VARS },
      data: {
        [graphqlEntityName]: {
          __typename: graphqlResultTypename,
          data: [],
          total: 0,
          ...PAGE_ONE_VARS,
        },
      },
    });

  // We want to start from the fresh state everytime filters/sort get changed and when we come back from a different page,
  // therefore we clear the cache
  useMountAndUpdateEffect(
    {
      onMount: () => {
        executeClearCache();
      },
      onUpdate: ([prevVariables]) => {
        if (!isEqual(variables, prevVariables)) {
          onReset();
          executeClearCache();
        }
      },
    },
    [variables]
  );
}

function useHandleApolloError() {
  const { errorMessage } = useFlashMessageContext();
  const intl = useIntl();
  const mounted = useMountedIndicator();

  return useCallback(
    ({ error, messageId }) => {
      if (!mounted.current) {
        // Ignoring errors when component is unmounted.
        // This also avoids internal apollo's error that occurs in such situation:
        // https://github.com/apollographql/apollo-client/issues/4114
        return;
      }
      errorMessage(formatErrorWithMessage({ error, messageId, intl }));
    },
    [intl, mounted, errorMessage]
  );
}

function usePaginationState() {
  const [page1Loaded, setPage1Loaded] = useStateIfMounted(false);
  const pagesLoaded = useRef(0);

  const onReset = useCallback(() => {
    setPage1Loaded(false);
    pagesLoaded.current = 0;
  }, [setPage1Loaded]);
  const onFirstPageLoaded = useCallback(
    data => {
      setPage1Loaded(true);
      if (data && pagesLoaded.current < 1) {
        pagesLoaded.current = 1;
      }
    },
    [setPage1Loaded]
  );
  const getPage = useCallback(() => pagesLoaded.current, []);
  const setPage = useCallback(pageIndex => {
    pagesLoaded.current = Math.max(
      pagesLoaded.current,
      pageIndex - PAGE_ONE + 1
    );
  }, []);

  return {
    page1Loaded,
    getPage,
    setPage,
    onReset,
    onFirstPageLoaded,
  };
}

export function usePaginatedQuery({ query, options, variables, pollInterval }) {
  const {
    getPage,
    setPage,
    onFirstPageLoaded,
    page1Loaded,
    onReset: onPageReset,
  } = usePaginationState();
  const handleError = useHandleApolloError();

  const [loadNextError, setLoadNextError] = useStateIfMounted();
  const { data, loading, error: firstLoadError, fetchMore, refetch } = useQuery(
    query,
    {
      ...options,
      // Normal load for the first page and then let it be handled by `fetchMore` and `refetch` custom calls
      // Otherwise unnecessary requests are made on every refetch for some reason
      fetchPolicy: page1Loaded ? 'cache-first' : 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: newData => {
        // This listener is also called on error and it sees stale data, so it's only used to mark the first page load
        onFirstPageLoaded(newData);
        options?.onCompleted && options.onCompleted(newData);
      },
      onError: error => handleError({ error, messageId: 'error.loadingData' }),
      variables: {
        ...variables,
        pageIndex: PAGE_ONE,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    }
  );

  const fetchMoreLock = useRef(null);
  const refetchLock = useRef(null);

  const loadNext = useCallback(async () => {
    // Don't load if there already is fetchMore in progress (may be caused by quick succession of scroll events)
    if (fetchMoreLock.current) {
      return;
    }
    // Don't load until refetch is finished
    await refetchLock.current;
    // await gives away control, so this may have been set in the meantime
    if (fetchMoreLock.current) {
      return;
    }

    setLoadNextError(undefined);

    const promise = fetchMore({
      variables: {
        ...variables,
        pageIndex: getPage() + 1,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    }).catch(error => {
      handleError({ error, messageId: 'error.loadingData' });
      setLoadNextError(error);
    });
    fetchMoreLock.current = promise.catch(() => null);
    const { data: newData } = await promise;
    const { pageIndex: newPageIndex } = extractGraphqlEntity(newData);
    setPage(newPageIndex);

    // Must reset, because here we don't want to wait until completion but discard any concurrent fetch
    fetchMoreLock.current = null;
  }, [setLoadNextError, fetchMore, variables, getPage, setPage, handleError]);

  // Variables may be passed as an inline object, so we have to cache them to prevent changing "refresh" all the time
  const cachedVariables = useCachedProp(variables);

  // Auto-refresh
  // We need to tweak variables (page size), so `pollInterval` can't be used
  // Changing variables in the base query would cause fetch in every rerender
  const refresh = useCallback(async () => {
    // Don't start refetching until fetchMore is finished
    await fetchMoreLock.current;

    refetchLock.current = refetch({
      ...cachedVariables,
      pageIndex: PAGE_ONE,
      pageSize: getPage() * DEFAULT_PAGE_SIZE,
    }).catch(error =>
      handleError({ error, messageId: 'error.refreshingData' })
    );
    await refetchLock.current;
  }, [getPage, handleError, refetch, cachedVariables]);
  useEffect(() => {
    if (!isNumber(pollInterval)) {
      return noop;
    }
    const interval = setInterval(refresh, pollInterval);
    return () => clearInterval(interval);
  }, [pollInterval, refresh]);

  const { data: rows = [], total } = data ? extractGraphqlEntity(data) : {};
  // `loadNextError` is definitely newer, so it's preferred
  const error = loadNextError || firstLoadError;
  const hasNext = !data || loading || rows.length < total;
  const loadNextIfPossible = !loading && hasNext ? loadNext : noop;

  return {
    rows: page1Loaded ? rows : [],
    hasNext,
    loadNext: loadNextIfPossible,
    totalRows: total,
    error,
    loading,
    loadingPage1: loading && !page1Loaded,
    onPageReset,
    refresh,
  };
}
