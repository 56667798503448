import { Row } from 'antd';
import classNames from 'classnames';
import { isString, noop } from 'lodash-es';
import { Children, cloneElement, forwardRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { useNodeInnerText } from '../common/utils/stringUtils';
import { cssVariables } from '../styles/cssVariables';
import { pxToNumber } from '../utils/cssUtils';
import Whitespace from './Whitespace';
import { FAIcon } from './adapters/fontAwesomeAdapters';

function useEhnancedOnClick(onClick, to) {
  const { push } = useHistory();
  return useCallback(
    e => {
      if (to) {
        push(to);
      }
      onClick && onClick(e);
    },
    [onClick, push, to]
  );
}

export function InlineLink({
  component: C = 'span',
  className,
  loading,
  disabled,
  children,
  onClick: onClickOuter,
  to,
  iconBefore,
  iconAfter,
  textId,
  ...rest
}) {
  const nonClickable = disabled || loading;
  const onClick = useEhnancedOnClick(onClickOuter, to);
  return (
    <C
      className={classNames('InlineLink', { disabled, loading }, className)}
      onClick={nonClickable ? noop : onClick}
      {...rest}
    >
      {iconBefore && <>{iconBefore}</>}
      <span>{textId ? <FormattedMessage id={textId} /> : children}</span>
      {iconAfter && <>{iconAfter}</>}
      {loading && (
        <>
          <Whitespace />
          <FAIcon icon="spinner" className="fa-spin" />
        </>
      )}
    </C>
  );
}

export const BlockLink = forwardRef(function BlockLink(
  { component: C = 'div', className, onClick: onClickOuter, to, ...rest },
  ref
) {
  const onClick = useEhnancedOnClick(onClickOuter, to);
  return (
    <C
      ref={ref}
      className={classNames('Clickable', className)}
      onClick={onClick}
      {...rest}
    />
  );
});

export function LabelWithValue({
  className,
  labelId,
  label,
  textId,
  text,
  enumPrefix,
  enumValue,
  labelSize = 'sm',
  size = 'sm',
  nowrap,
  labelNowrap,
  showNewlines,
  valueProps,
  ...rest
}) {
  const intl = useIntl();

  let textValue;
  if (enumPrefix) {
    textValue = enumValue
      ? intl.formatMessage({ id: `${enumPrefix}.${enumValue}` })
      : enumValue || '-';
  } else {
    textValue = textId ? intl.formatMessage({ id: textId }) : text || '-';
  }

  const { ref: labelRef, text: labelTitle } = useNodeInnerText();
  const { ref: valueRef, text: valueTitle } = useNodeInnerText();

  return (
    <Row
      className={classNames(
        'LabelWithValue',
        `size-${size}`,
        {
          'flex-nowrap': nowrap && labelSize !== 'full',
          nowrap,
          'label-nowrap': labelNowrap,
        },
        className
      )}
      align="middle"
      {...rest}
    >
      <div
        className={classNames('label', `size-${labelSize}`)}
        ref={labelRef}
        title={labelTitle}
      >
        {labelId ? <FormattedMessage id={labelId} /> : label}
      </div>
      <div
        className={classNames('text', { 'nl-as-newline': showNewlines })}
        ref={valueRef}
        title={valueTitle}
        {...valueProps}
      >
        {textValue}
      </div>
    </Row>
  );
}

export function BlockLabelWithValue({
  className,
  labelId,
  label,
  textId,
  text,
  size = 'sm',
  ...rest
}) {
  const { intl } = useIntl();
  const textValue = textId ? intl.formatMessage({ id: textId }) : text;
  const lines = isString(textValue)
    ? textValue
        .split(/\n/g)
        // eslint-disable-next-line react/no-array-index-key
        .map((line, i) => <div key={`${i}${line}`}>{line}</div>)
    : textValue;

  const { ref, text: title } = useNodeInnerText();

  return (
    <div
      className={classNames('BlockLabelWithValue', `size-${size}`, className)}
      {...rest}
    >
      <div className="label">
        {labelId ? <FormattedMessage id={labelId} /> : label}
      </div>
      <div className="text" flex="auto" ref={ref} title={title}>
        {lines}
      </div>
    </div>
  );
}

export function LabelRow({ className, children, noBorder, ...rest }) {
  const numCols = Children.count(children);
  return (
    <Row
      className={classNames('LabelRow', { 'no-border': noBorder }, className)}
      align="top"
      gutter={pxToNumber(cssVariables.spaceSm)}
      {...rest}
    >
      {Children.map(children, ch =>
        cloneElement(ch, { span: Math.floor(24 / numCols) })
      )}
    </Row>
  );
}
