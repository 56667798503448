import { Form } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { UnitSystem } from '../../../app/enums/measuringUnits';
import { SettingsContext } from '../../../components/domainSpecific/settingsElements';

export function mapFormValuesToDependencies(formValues) {
  return {
    ...formValues,
    // Synthetic values used for dependencies only
    accountNumber: formValues.account?.number,
    pickupDateTime: formValues.origin.pickupDateTime,
    deliveryDateTime: formValues.destination.deliveryDateTimeAsap
      ? ''
      : formValues.destination.deliveryDateTime,
    serviceInformation: {
      ...formValues.serviceInformation,
      // Virtual field used as a dependency for some BE endpoints
      isMetric: formValues.unitSystem === UnitSystem.METRIC,
    },
  };
}

export default function useNewShipmentFormState() {
  const [account, setAccount] = useState();
  const [values, setValues] = useState({});
  const {
    unitSystem: parentUnitSystem,
    loading: loadingUnitSystem,
  } = useContext(SettingsContext);
  const [unitSystemOverride, setUnitSystemOverride] = useState();

  // We don't want to allow changing unit system in the middle of creating shipment,
  // therefore we capture the initial value
  const [initialParentUnitSystem, setInitialParentUnitSystem] = useState(null);
  useEffect(() => {
    if (!loadingUnitSystem && !initialParentUnitSystem) {
      setInitialParentUnitSystem(parentUnitSystem);
    }
  }, [initialParentUnitSystem, loadingUnitSystem, parentUnitSystem]);

  // Refresh may be called immediately after a sequence of setters and we want it
  // to work with the newest possible values
  const currentState = useRef({ account, values, unitSystemOverride });

  // Keep in sync with steps - hooks cannot be called inside loops
  const [form1] = Form.useForm();
  const form1Ref = useRef();
  const [form2] = Form.useForm();
  const form2Ref = useRef();
  const [form3] = Form.useForm();
  const form3Ref = useRef();
  const [form4] = Form.useForm();
  const form4Ref = useRef();
  const forms = [
    { form: form1, name: 'origin', formRef: form1Ref },
    { form: form2, name: 'destination', formRef: form2Ref },
    { form: form3, name: 'serviceInformation', formRef: form3Ref },
    { form: form4, name: 'packageList', formRef: form4Ref },
  ];

  // Extracts the current form values, contains all the data required for submit
  const extractValues = useCallback(
    overrides =>
      mapFormValuesToDependencies({
        account: currentState.current.account,
        origin: form1.getFieldsValue(),
        destination: form2.getFieldsValue(),
        serviceInformation: form3.getFieldsValue(),
        packageList: form4.getFieldsValue(),
        unitSystem:
          currentState.current.unitSystemOverride || initialParentUnitSystem,
        ...overrides,
      }),
    [form1, form2, form3, form4, initialParentUnitSystem]
  );

  const refreshValues = useCallback(
    overrides => {
      const newValues = {
        ...currentState.current.values,
        ...extractValues(overrides),
      };
      setValues(newValues);
      currentState.current.values = newValues;
      return newValues;
    },
    [extractValues]
  );

  return {
    forms,
    refreshValues,
    account,
    setAccount: acc => {
      setAccount(acc);
      currentState.current.account = acc;
      refreshValues();
    },
    unitSystem: unitSystemOverride || initialParentUnitSystem,
    setUnitSystem: us => {
      setUnitSystemOverride(us);
      currentState.current.unitSystemOverride = us;
      refreshValues();
    },
    values,
    extractValues,
  };
}
