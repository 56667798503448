import { Menu, Popover } from 'antd';
import { compact, every, isNumber, property, range, values } from 'lodash-es';
import {
  Children,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { useMountAndUpdateEffect } from '../../common/utils/hookUtils';
import GoogleMapAdapter from '../../components/adapters/GoogleMapAdapter';
import { FAIconChars } from '../../components/adapters/fontAwesomeAdapters';
import {
  iconLabel,
  imgIcon,
} from '../../components/adapters/googleMap/googleMapElements';
import { makeMarker } from '../../components/adapters/googleMap/googleMapHelpers';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../../components/layout/Scrollbars';
import { MapCard } from '../../components/maps/MapCard';
import iconBlue from '../../resources/img/icons/map-circle-blue.png';
import iconGrey from '../../resources/img/icons/map-circle-grey.png';
import iconYellow from '../../resources/img/icons/map-circle-yellow.png';
import groupingIcon from '../../resources/img/icons/map-grouping.png';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { latLng, mapSize } from '../../utils/mapUtils';
import MonitorJobMapLegend from './MonitorJobMapLegend';
import { MonitorContext, getLocationModeInfo } from './monitorContexts';

function nonDeliveredMarker() {
  return {
    icon: imgIcon(iconBlue, mapSize(40, 40)),
  };
}
function delayedMarker() {
  return {
    icon: imgIcon(iconYellow, mapSize(40, 40)),
    label: iconLabel(
      FAIconChars.EXCLAMATION,
      cssVariables.colorWhite,
      cssVariables['font-size-sm-minus']
    ),
    tooltipContent: <FormattedMessage id="monitor.map.marker.revisedEta" />,
  };
}
function deliveredMarker() {
  return {
    icon: imgIcon(iconGrey, mapSize(40, 40)),
    label: iconLabel(
      FAIconChars.CHECK,
      cssVariables.colorWhite,
      cssVariables['font-size-sm-minus']
    ),
    tooltipContent: <FormattedMessage id="monitor.map.marker.delivered" />,
  };
}
function jobMarker({ serviceUpdate, deliveryDateTime }) {
  if (deliveryDateTime?.actual) {
    return deliveredMarker();
  }
  if (serviceUpdate) {
    return delayedMarker();
  }
  return nonDeliveredMarker();
}

function useMarkers() {
  const { data, activeSection } = useContext(MonitorContext);
  const firstLoadDone = every(
    compact(values(data)).map(grp => grp.rows.length > 0 || !grp.loading)
  );
  const expandedJobs = data[activeSection]?.rows;

  const { locationMode, setLocationMode, ...activeJobCtrl } = useContext(
    MonitorContext
  );
  const activeJobCtrlRef = useRef(activeJobCtrl);
  useEffect(() => {
    activeJobCtrlRef.current = activeJobCtrl;
  });

  const markers = useMemo(
    () =>
      expandedJobs
        ?.map(job => {
          const { getJobLocation } = getLocationModeInfo(locationMode);
          return {
            location: getJobLocation(job)?.location,
            jobNumber: job.jobNumber,
            serviceUpdate: job.serviceUpdate,
            deliveryDateTime: job.deliveryDateTime,
          };
        })
        .filter(property('location'))
        .map(({ location, jobNumber, serviceUpdate, deliveryDateTime }) => {
          const { lat, lng } = location;

          return makeMarker(latLng(lat, lng), {
            ...jobMarker({ serviceUpdate, deliveryDateTime }),
            data: { jobNumber },
            shape: {
              type: 'circle',
              coords: [20, 20, 10],
            },
            onMouseOver: () => {
              const { activeJob, setActiveJob } = activeJobCtrlRef.current;
              if (activeJob !== jobNumber) {
                setActiveJob(jobNumber);
              }
            },
          });
        }),
    [expandedJobs, locationMode]
  );

  return { markers, firstLoadDone };
}

function ClusterTooltipContentWrapper({ maxCount, maxHeight, children }) {
  const [size, setSize] = useState();
  const count = Children.count(children);
  useMountAndUpdateEffect(
    {
      onUpdate: () => {
        setSize(undefined);
      },
    },
    [count]
  );
  const menuRef = useCallback(
    ref => {
      if (ref) {
        setSize({
          width: ref.clientWidth,
          height: Math.min(
            ref.scrollHeight,
            (ref.scrollHeight / count) * maxCount
          ),
        });
      }
    },
    [count, maxCount]
  );

  const useScrollbar =
    count > maxCount ||
    (isNumber(maxHeight) && size && size.height > maxHeight);

  return useScrollbar && size ? (
    <Scrollbars
      vertical={VerticalTrackTypes.ABOVE_CONTENT}
      style={{
        width: size.width,
        height: Math.min(size.height, ...[maxHeight].filter(isNumber)),
      }}
    >
      <Menu>{children}</Menu>
    </Scrollbars>
  ) : (
    <div ref={menuRef}>
      <Menu>{children}</Menu>
    </div>
  );
}

function ClusterTooltip({ jobNumbers, rect, rectElement }) {
  const { setActiveJob } = useContext(MonitorContext);
  const topSpace = rect.top;
  const bottomSpace = document.body.clientHeight - rect.bottom;
  const maxHeight =
    Math.max(topSpace, bottomSpace) - pxToNumber(cssVariables.spaceNorm2);

  return (
    <Popover
      overlayClassName="MapDropdownMenu-Overlay"
      placement={topSpace >= bottomSpace ? 'top' : 'bottom'}
      autoAdjustOverflow={false}
      trigger="hover"
      content={
        <ClusterTooltipContentWrapper maxCount={15} maxHeight={maxHeight}>
          {jobNumbers.map(jobNumber => (
            <Menu.Item
              key={jobNumber}
              onMouseOver={() => setActiveJob(jobNumber)}
            >
              {jobNumber}
            </Menu.Item>
          ))}
        </ClusterTooltipContentWrapper>
      }
    >
      {rectElement}
    </Popover>
  );
}

const CLUSTERER_STYLES = range(0, 5).map(() => ({
  url: groupingIcon,
  width: 40,
  height: 40,
  textColor: cssVariables.colorWhite,
  textSize: pxToNumber(cssVariables['font-size-base']),
  fontFamily: cssVariables.fontStd,
  fontWeight: 700,
}));

function getClusterTooltip({ markers: ms, rect, rectElement }) {
  return (
    ms.length > 1 && (
      <ClusterTooltip
        key={ms.map(m => m?.data?.jobNumber).join(',')}
        jobNumbers={ms.map(m => m?.data?.jobNumber)}
        rect={rect}
        rectElement={rectElement}
      />
    )
  );
}
const CLUSTERER_PROPS = {
  enable: true,
  getTooltip: getClusterTooltip,
  styles: CLUSTERER_STYLES,
};

const defaultRenderMap = map => <MapCard>{map}</MapCard>;
export default function MonitorJobMap({ renderMap = defaultRenderMap }) {
  const { markers, firstLoadDone } = useMarkers();

  return renderMap(
    <GoogleMapAdapter
      markers={markers}
      // This is to set bounds only after all the groups were loaded
      markersReady={firstLoadDone}
      clustererProps={CLUSTERER_PROPS}
    >
      <MonitorJobMapLegend />
    </GoogleMapAdapter>
  );
}
