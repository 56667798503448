import { isNumber } from 'lodash-es';

import { numberOr } from '../../common/utils/numberUtils';
import { richSelectValueToCodeValueInput } from '../../components/forms/selectUtils';
import {
  extractActualWeight,
  getValidPackages,
} from '../../forms/shipments/shipmentCommon';
import {
  DeviceAddonType,
  getDeviceAddonLabel,
  mapDeviceAddonsInput,
} from './deviceAddonConversions';
import {
  formToInputJobLocation,
  graphQLToFormJobLocation,
} from './locationConversions';
import { formToGraphqlDangerousGoods } from './newShipmentConversions';

export function shipmentTemplateFormToInput({
  name,
  accountNumber,
  origin,
  destination,
  serviceInformation,
  packageList,
  unitSystem,
}) {
  return {
    name,
    accountNumber,
    origin: formToInputJobLocation(origin),
    destination: formToInputJobLocation(destination),
    serviceInformation: {
      serviceType: richSelectValueToCodeValueInput(
        serviceInformation.serviceType
      ),
      commodity: serviceInformation.commodity,
      additionalInsurance: numberOr(
        parseFloat(serviceInformation.additionalInsurance),
        null
      ),
      declaredValue: numberOr(
        parseFloat(serviceInformation.declaredValue),
        null
      ),
      costCenter: serviceInformation.costCenter,
      ...formToGraphqlDangerousGoods(serviceInformation),
    },
    packages: packageList.tbd
      ? []
      : getValidPackages(packageList.packages)
          ?.filter(pkg => !!pkg) // Ant deletes a form item by first setting it to null, which causes problems
          .map(pkg => ({
            packageSource: richSelectValueToCodeValueInput(pkg.packageSource),
            packagingType: richSelectValueToCodeValueInput(pkg.packagingType),
            commodityTemperature: richSelectValueToCodeValueInput(
              pkg.commodityTemperature
            ),
            packageQualificationTime: pkg.packageQualificationTime,
            actualWeight: extractActualWeight(pkg),
            length: pkg.length,
            width: pkg.width,
            height: pkg.height,
            ...mapDeviceAddonsInput(pkg.deviceAddons),
            pieces: pkg.pieces?.map(piece => ({
              description: piece.description,
              partNumber: piece.partNumber,
              quantity: piece.quantity,
            })),
            coolantAddon: {
              type: richSelectValueToCodeValueInput(pkg.coolantAddon?.type),
              source: richSelectValueToCodeValueInput(pkg.coolantAddon?.source),
              amount: pkg.coolantAddon?.amount || null,
              replenishTime: pkg.coolantAddon?.replenishTime || null,
            },
          })),
    unitSystem,
  };
}

function graphQLToFormDeviceAddon(deviceAddon, type) {
  return {
    device: {
      code: deviceAddon.deviceCode,
      text: getDeviceAddonLabel(deviceAddon),
    },
    type,
    brandName: deviceAddon.brandName,
    modelName: deviceAddon.modelName,
    serialNumber: deviceAddon.serialNumber,
    source: deviceAddon.source,
  };
}

export function shipmentTemplateGraphQLToForm({
  origin,
  destination,
  serviceInformation,
  packages,
  unitSystem,
}) {
  return {
    origin: graphQLToFormJobLocation(origin),
    destination: graphQLToFormJobLocation(destination),
    serviceInformation: {
      serviceType: serviceInformation.serviceType,
      commodity: serviceInformation.commodity,
      dangerousGoods: serviceInformation.dangerousGoods,
      exceptedQuantity: serviceInformation.exceptedQuantity,
      unNumber: serviceInformation.unNumber,
      additionalInsurance: serviceInformation.additionalInsurance,
      declaredValue: serviceInformation.declaredValue,
      costCenter: serviceInformation.costCenter,
    },
    packageList: {
      packages:
        packages?.map(pkg => ({
          packageSource: pkg.packageSource,
          packagingType: pkg.packagingType,
          commodityTemperature: pkg.commodityTemperature,
          packageQualificationTime: pkg.packageQualificationTime,
          actualWeight: pkg.actualWeight,
          unknownWeight: !isNumber(pkg.actualWeight),
          length: pkg.length,
          width: pkg.width,
          height: pkg.height,
          deviceAddons: [
            ...(pkg.gpsDevices?.map(da =>
              graphQLToFormDeviceAddon(da, DeviceAddonType.GPS)
            ) || []),
            ...(pkg.temperatureDevices?.map(da =>
              graphQLToFormDeviceAddon(da, DeviceAddonType.TEMPERATURE)
            ) || []),
          ],
          pieces: pkg.pieces?.map(piece => ({
            description: piece.description,
            partNumber: piece.partNumber,
            quantity: piece.quantity,
          })),
          coolantAddon: pkg.coolantAddon,
        })) || [],
    },
    unitSystem,
  };
}
