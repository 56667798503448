/* eslint-disable import/no-unused-modules */
// components are not used temporarily because problems with news service provider

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { isNumber, noop, range } from 'lodash-es';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import sanitize from 'sanitize-html';

import { SERVICE_ALERTS_QUERY } from '../app/graphql/serviceAlertsQueries';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import { DotButton } from '../components/buttons/buttons';
import { ThreeDotsSpinner } from '../components/data/Spinner';
import { DataError } from '../components/data/dataStateHandlers';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../components/layout/Scrollbars';
import { Flex1 } from '../components/layout/layoutElements';
import { SlidingContent } from '../components/nav/SlidingContent';
import { cssVariables } from '../styles/cssVariables';
import { msToNumber } from '../utils/cssUtils';

const ALERT_TITLE_SPACE = 56;
const SCROLL_VELOCITY = 90; // px/s
const AUTO_CHANGE_INTERVAL = 6000;

function AlertTitle({ alert }) {
  const containerRef = useRef();
  const textRef = useRef();
  const [scrollInfo, setScrollInfo] = useState(undefined);

  useLayoutEffect(() => {
    if (containerRef.current && textRef.current) {
      const { clientWidth: containerWidth } = containerRef.current;
      const { clientWidth: textWidth } = textRef.current;

      const width = textWidth + ALERT_TITLE_SPACE;
      const count = Math.ceil(containerWidth / width) + 1;

      setScrollInfo({
        count,
        width,
        duration: (width * count) / SCROLL_VELOCITY,
      });
    }
  }, []);

  const getMsDuration = (multiplier = 1) =>
    `${Math.round((scrollInfo?.duration || 0) * 1000 * multiplier)}ms`;

  return (
    <div className="ServiceAlerts-AlertTitle" ref={containerRef}>
      <>
        {scrollInfo ? (
          <div
            className={classNames(
              'ServiceAlerts-AlertTitleScrollContainer',
              `count-${scrollInfo.count}`
            )}
            style={{ width: scrollInfo.width }}
          >
            {range(0, scrollInfo.count).map(i => (
              <div
                key={i}
                className="ServiceAlerts-AlertTitleScroller"
                style={{
                  animationDuration: getMsDuration(),
                  animationDelay: getMsDuration(-i / scrollInfo.count),
                }}
              >
                {alert.title}
              </div>
            ))}
          </div>
        ) : (
          <span>{alert.title}</span>
        )}
        <div className="ServiceAlerts-AlertTitleMeasurement" ref={textRef}>
          {alert.title}
        </div>
      </>
    </div>
  );
}

export function AlertText({ alert }) {
  const cleanHtml = sanitize(alert.description, { allowedTags: ['p'] });
  return (
    <div
      className="ServiceAlerts-Text"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
    />
  );
}

export function useServiceAlertsState() {
  const { data, loading, error } = useQuery(SERVICE_ALERTS_QUERY);
  const alerts = data
    ? extractGraphqlEntity(data)?.data?.map((alert, i) => ({
        ...alert,
        index: i,
      }))
    : [];
  const [activeIndex, setActiveIndex] = useState();

  useEffect(() => {
    if (data) {
      if (!isNumber(activeIndex)) {
        setActiveIndex(0);
      } else {
        const timeout = setTimeout(() => {
          setActiveIndex((activeIndex + 1) % alerts.length);
        }, AUTO_CHANGE_INTERVAL);
        return () => clearTimeout(timeout);
      }
    }
    return noop;
  }, [activeIndex, alerts.length, data]);

  return {
    alerts,
    loading,
    error,
    active: alerts[activeIndex],
    setActiveIndex,
  };
}

export function ServiceAlertsHeader({ className, alerts, loading, active }) {
  const slidingData = alerts.map(alert => ({
    name: alert.index,
    props: { alert },
  }));

  return (
    <div className={classNames('ServiceAlerts', className)}>
      <div className="ServiceAlerts-Title">
        <FormattedMessage id="serviceAlerts.title" />
      </div>
      <div className="ServiceAlerts-Ticker">
        {loading ? (
          <ThreeDotsSpinner />
        ) : (
          <SlidingContent
            className="anim-lg"
            ContentComponent={AlertTitle}
            data={slidingData}
            selected={active?.index}
            listenToSelected
            vertical
          />
        )}
      </div>
    </div>
  );
}

export function ServiceAlertsBody({ className, error, active }) {
  return (
    <Flex1 className={className}>
      <Scrollbars vertical={VerticalTrackTypes.OUTSIDE}>
        <div
          className="ServiceAlerts-TextContainer"
          data-subject="service-alerts"
          data-role="content"
        >
          {error && <DataError error={error} />}
          <TransitionGroup component={null}>
            {active && (
              <CSSTransition
                key={active.index}
                timeout={msToNumber(cssVariables.animationDurationLg)}
                classNames="fade-from-left"
              >
                <AlertText alert={active} />
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </Scrollbars>
    </Flex1>
  );
}

export function ServiceAlertsControlButtons({
  className,
  alerts,
  active,
  setActiveIndex,
}) {
  return (
    <div className={classNames('ServiceAlerts-ControlButtons', className)}>
      {alerts.map(({ index }) => (
        <DotButton
          key={index}
          active={active?.index === index}
          onClick={() => setActiveIndex(index)}
        />
      ))}
    </div>
  );
}
