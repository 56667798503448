import { Col, Row, Tooltip } from 'antd';
import { chunk, get, isEmpty, map, some } from 'lodash-es';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  formatCodeValue,
  formatCodeValueShort,
  formatLocationLong,
} from '../../../common/utils/formatUtils';
import { useNodeInnerText } from '../../../common/utils/stringUtils';
import Whitespace from '../../../components/Whitespace';
import { CurrencyFormat } from '../../../components/domainSpecific/currencyElements';
import { WeightFormat } from '../../../components/domainSpecific/measuringUnitsElements';
import { YesNoFormat } from '../../../components/forms/checkable';
import { SimpleCardSubtitle } from '../../../components/layout/cardElements';
import { BlockLabelWithValue, LabelRow } from '../../../components/typography';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { DateTimeFormatMedium } from '../../../utils/dateFormatting';
import {
  formatDateTimeSchedule,
  formatShipperSpecificInfoField,
} from '../shipmentFormats';
import {
  ShipmentSummaryCard,
  ShipmentSummaryCardSection,
  ShipmentSummaryDateTimeFormatFlight,
  ShipmentSummaryLabelWithValue,
  TwoLineLabelWithValue,
} from './shipmentSummariesCommon';

function getShipperSpecificInformationContent(shipment) {
  const shipperSpecificInfo = get(
    shipment,
    'serviceInformation.shipperSpecificInfo',
    []
  );
  if (!isEmpty(shipperSpecificInfo)) {
    const chunks = chunk(shipperSpecificInfo, 2);
    return chunks.map(([col1Info, col2Info], i) => (
      // eslint-disable-next-line react/no-array-index-key
      <LabelRow key={i} noBorder>
        <Col>
          <TwoLineLabelWithValue
            label={col1Info?.label}
            text={formatShipperSpecificInfoField(col1Info)}
          />
        </Col>
        <Col>
          {col2Info && (
            <TwoLineLabelWithValue
              label={col2Info.label}
              text={formatShipperSpecificInfoField(col2Info)}
            />
          )}
        </Col>
      </LabelRow>
    ));
  }
  return null;
}

function ShipperSpecificInformation({ shipment }) {
  const shipperSpecificInformation = getShipperSpecificInformationContent(
    shipment
  );
  return (
    shipperSpecificInformation && (
      <>
        <SimpleCardSubtitle titleId="shipmentDetail.shipperSpecificInformation" />
        <ShipmentSummaryCardSection>
          {shipperSpecificInformation}
        </ShipmentSummaryCardSection>
      </>
    )
  );
}

function AirlineFlightStatusCard({ shipment }) {
  return (
    <ShipmentSummaryCard
      className="AirlineFlightStatusCard"
      titleId="shipmentDetail.airlineFlightStatus"
    >
      {shipment?.metadata?.blindingInformation?.flights ? (
        <div className="ShipmentDetail__BlindedFlights">
          <FormattedMessage id="gdpr.blinded" />
        </div>
      ) : (
        (shipment?.flights || []).map((flight, i) => {
          const estimateExists = some(
            [flight.departure, flight.arrival],
            sch => sch.estimated
          );
          const actualExists = some(
            [flight.departure, flight.arrival],
            sch => sch.actual
          );
          return (
            <Fragment key={get(flight, 'number')}>
              <div className="AirlineFlightStatusCard-Header">
                <span className="CommonLabelValue-Label">
                  <FormattedMessage id="shipmentDetail.awb" />:{' '}
                </span>
                <span className="CommonLabelValue-Text">
                  {get(flight, 'awb')}
                </span>
              </div>
              <div className="AirlineFlightStatusCard-Section">
                <Row align="top" gutter={pxToNumber(cssVariables.spaceMd)}>
                  <Col>
                    <div className="AirlineFlightStatusCard-Index">{i + 1}</div>
                  </Col>
                  <Col className="Flex1">
                    <Row
                      justify="space-between"
                      className="AirlineFlightStatusCard-MediumFont"
                      style={{ marginBottom: cssVariables.spaceSm }}
                    >
                      <Col>
                        <Row gutter={pxToNumber(cssVariables.spaceXs)}>
                          <Col>
                            <div className="CommonLabelValue-Label">
                              <FormattedMessage id="shipmentDetail.airlineFlightStatus.airline" />{' '}
                              /
                            </div>
                            <div className="CommonLabelValue-Text">
                              {get(flight, 'airline')}
                            </div>
                          </Col>
                          <Col>
                            <div className="CommonLabelValue-Label">
                              <FormattedMessage id="shipmentDetail.airlineFlightStatus.flight" />
                            </div>
                            <div className="CommonLabelValue-Text">
                              {get(flight, 'number')}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <div className="CommonLabelValue-Label">
                          <FormattedMessage id="shipmentDetail.airlineFlightStatus.origin" />
                        </div>
                        <div className="CommonLabelValue-Text">
                          {get(flight, 'originAirportCode')}
                        </div>
                      </Col>
                      <Col>
                        <div className="CommonLabelValue-Label">
                          <FormattedMessage id="shipmentDetail.airlineFlightStatus.destination" />
                        </div>
                        <div className="CommonLabelValue-Text">
                          {get(flight, 'destinationAirportCode')}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      align="top"
                      className="AirlineFlightStatusCard-LargeFont"
                      gutter={pxToNumber(cssVariables.spaceSm)}
                      style={{ marginBottom: cssVariables.spaceXs }}
                    >
                      <Col className="CommonLabelValue-Label">
                        <FormattedMessage id="shipmentDetail.airlineFlightStatus.routeStatus" />
                        :
                      </Col>
                      <Col className="CommonLabelValue-Text text-uppercase">
                        {get(flight, 'status')}
                      </Col>
                    </Row>
                    <Row
                      align="top"
                      className="AirlineFlightStatusCard-SmallFont"
                    >
                      <Col span={9}>
                        <div className="indent-left">
                          <div className="CommonLabelValue-Label">
                            <FormattedMessage id="shipmentDetail.airlineFlightStatus.flightUpdates" />
                          </div>
                          <div className="CommonLabelValue-Text">
                            <FormattedMessage id="shipmentDetail.airlineFlightStatus.scheduled" />
                          </div>
                          {estimateExists && (
                            <div className="CommonLabelValue-Text">
                              <FormattedMessage id="shipmentDetail.airlineFlightStatus.estimated" />
                            </div>
                          )}
                          {actualExists && (
                            <div className="CommonLabelValue-Text">
                              <FormattedMessage id="shipmentDetail.airlineFlightStatus.actual" />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="CommonLabelValue-Label">
                          <FormattedMessage id="shipmentDetail.airlineFlightStatus.depart" />
                        </div>
                        <div className="CommonLabelValue-Text">
                          <ShipmentSummaryDateTimeFormatFlight
                            value={get(flight, 'departure.scheduled')}
                          />
                        </div>
                        {estimateExists && (
                          <div className="CommonLabelValue-Text">
                            <ShipmentSummaryDateTimeFormatFlight
                              value={get(flight, 'departure.estimated')}
                            />
                          </div>
                        )}
                        {actualExists && (
                          <div className="CommonLabelValue-Text">
                            <ShipmentSummaryDateTimeFormatFlight
                              value={get(flight, 'departure.actual')}
                            />
                          </div>
                        )}
                      </Col>
                      <Col span={7}>
                        <div className="CommonLabelValue-Label">
                          <FormattedMessage id="shipmentDetail.airlineFlightStatus.arrive" />
                        </div>
                        <div className="CommonLabelValue-Text">
                          <ShipmentSummaryDateTimeFormatFlight
                            value={get(flight, 'arrival.scheduled')}
                          />
                        </div>
                        {estimateExists && (
                          <div className="CommonLabelValue-Text">
                            <ShipmentSummaryDateTimeFormatFlight
                              value={get(flight, 'arrival.estimated')}
                            />
                          </div>
                        )}
                        {actualExists && (
                          <div className="CommonLabelValue-Text">
                            <ShipmentSummaryDateTimeFormatFlight
                              value={get(flight, 'arrival.actual')}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Fragment>
          );
        })
      )}
    </ShipmentSummaryCard>
  );
}

function ShipmentDangerousGoodsDeclaration({ shipment }) {
  const unNumbers = get(shipment, 'serviceInformation.unNumber');
  return (
    <ShipmentSummaryCard titleId="book.newShipment.dangerousGoodsDeclaration">
      <ShipmentSummaryCardSection className="spaces-vert-xs">
        <LabelRow>
          <Col>
            <ShipmentSummaryLabelWithValue
              labelSize="full"
              labelId="book.newShipment.label.dangerousGoods"
              text={
                <YesNoFormat
                  className="text-uppercase"
                  value={get(shipment, 'serviceInformation.dangerousGoods')}
                />
              }
            />
          </Col>
          <Col>
            <ShipmentSummaryLabelWithValue
              labelSize="full"
              labelId="book.newShipment.label.exceptedQuantity"
              text={
                <YesNoFormat
                  className="text-uppercase"
                  value={get(shipment, 'serviceInformation.exceptedQuantity')}
                />
              }
            />
          </Col>
        </LabelRow>
        {map(unNumbers, number => (
          <LabelRow>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelSize="full"
                labelId="book.newShipment.label.unNumber"
                text={formatCodeValueShort(number)}
              />
            </Col>
            <Col>
              <Tooltip title={formatCodeValue(number)}>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.unDescription"
                  text={formatCodeValue(number)}
                />
              </Tooltip>
            </Col>
          </LabelRow>
        ))}
      </ShipmentSummaryCardSection>
    </ShipmentSummaryCard>
  );
}

const BlockLabelDateTimeFormat = props => (
  <DateTimeFormatMedium {...props} separator=" | " />
);
function BlockLabel({ labelId, dateLabelId, dateValue, ...rest }) {
  const { ref, text } = useNodeInnerText();
  return (
    <>
      <div className="font-size-base">
        <FormattedMessage id={labelId} />
      </div>
      <div>
        <span className="ShipmentDetail__DateLabel">
          <FormattedMessage id={dateLabelId} />:<Whitespace />
        </span>
        <span
          ref={ref}
          className="ShipmentDetail__DateValue"
          title={text}
          {...rest}
        >
          {formatDateTimeSchedule(dateValue, BlockLabelDateTimeFormat)}
        </span>
      </div>
    </>
  );
}

function blindableText(text, { blinded }) {
  if (!blinded) {
    return text;
  }
  return (
    <div className="ShipmentDetail__BlindedAddress">
      <FormattedMessage id="gdpr.blinded" />
    </div>
  );
}

export function ShipmentOrderSummary({ shipment, showAirlineFlightStatus }) {
  return (
    <div className="margins-bottom-norm2">
      <ShipmentSummaryCard titleId="shipmentDetail.accountDetails">
        <ShipmentSummaryCardSection>
          <LabelRow noBorder>
            <Col>
              <TwoLineLabelWithValue
                labelId="shipmentDetail.company"
                text={get(shipment, 'metadata.account.name')}
              />
            </Col>
            <Col>
              <TwoLineLabelWithValue
                labelId="shipmentDetail.accountNumber"
                text={get(shipment, 'metadata.account.number')}
              />
            </Col>
          </LabelRow>
          {shipment?.metadata?.blindingInformation?.accountDetails ? (
            <LabelRow noBorder>
              <div className="ShipmentDetail__BlindedAccountDetails">
                <FormattedMessage id="gdpr.blinded" />
              </div>
            </LabelRow>
          ) : (
            <LabelRow noBorder>
              <Col>
                <TwoLineLabelWithValue
                  labelId="shipmentDetail.contact"
                  text={get(shipment, 'authorizedBy.contact.name')}
                />
              </Col>
              <Col>
                <TwoLineLabelWithValue
                  labelId="shipmentDetail.phone"
                  text={get(shipment, 'authorizedBy.contact.phone')}
                />
              </Col>
            </LabelRow>
          )}
        </ShipmentSummaryCardSection>
        <ShipperSpecificInformation shipment={shipment} />
      </ShipmentSummaryCard>
      <ShipmentSummaryCard titleId="shipmentDetail.shipmentDetails">
        <ShipmentSummaryCardSection>
          <LabelRow>
            <Col>
              <BlockLabelWithValue
                label={
                  <BlockLabel
                    labelId="shipmentDetail.pickupLocation"
                    dateLabelId="job.readyDate.short"
                    dateValue={get(shipment, 'pickupDateTime')}
                    data-info-id="pickupDateTime"
                    data-format="short"
                  />
                }
                text={blindableText(
                  formatLocationLong(shipment?.origin || {}),
                  { blinded: shipment?.metadata?.blindingInformation?.origin }
                )}
              />
            </Col>
            <Col>
              <BlockLabelWithValue
                label={
                  <BlockLabel
                    labelId="shipmentDetail.deliveryLocation"
                    dateLabelId="job.deadlineDate.short"
                    dateValue={get(shipment, 'deliveryDateTime')}
                    data-info-id="deliveryDateTime"
                    data-format="short"
                  />
                }
                text={blindableText(
                  formatLocationLong(shipment?.destination || {}),
                  {
                    blinded:
                      shipment?.metadata?.blindingInformation?.destination,
                  }
                )}
              />
            </Col>
          </LabelRow>
          <LabelRow>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.service"
                text={formatCodeValue(
                  get(shipment, 'serviceInformation.serviceType')
                )}
              />
            </Col>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.comodity"
                text={get(shipment, 'serviceInformation.commodity')}
              />
            </Col>
          </LabelRow>
          <LabelRow>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.packages"
                text={get(shipment, 'packagesInformation.count')}
              />
            </Col>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.weight"
                text={
                  <WeightFormat
                    value={get(shipment, 'packagesInformation.totalWeight')}
                  />
                }
              />
            </Col>
          </LabelRow>
          <LabelRow>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.referenceNumber"
                labelSize="full"
                showNewlines
                text={get(shipment, 'shipperReference')}
              />
            </Col>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.bolNumber"
                labelSize="full"
                text={get(shipment, 'serviceInformation.bolNumber')}
              />
            </Col>
          </LabelRow>
          <LabelRow>
            <Col>
              <ShipmentSummaryLabelWithValue
                label={
                  <Tooltip
                    title={
                      <FormattedMessage id="shipmentDetail.eoriNumber.tooltip" />
                    }
                    overlayClassName="ant-tooltip--sm"
                    placement="topLeft"
                  >
                    <FormattedMessage id="shipmentDetail.eoriNumber" />
                  </Tooltip>
                }
                text={get(shipment, 'serviceInformation.eoriNumber')}
              />
            </Col>
          </LabelRow>
          {shipment.serviceInformation?.hsInfo?.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <LabelRow key={i}>
              <Col>
                <ShipmentSummaryLabelWithValue
                  label={
                    <Tooltip
                      title={
                        <FormattedMessage id="shipmentDetail.hsCode.tooltip" />
                      }
                      overlayClassName="ant-tooltip--sm"
                      placement="topLeft"
                    >
                      <FormattedMessage id="shipmentDetail.hsCode" />
                    </Tooltip>
                  }
                  labelSize="full"
                  text={item.code}
                />
              </Col>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelId="shipmentDetail.hsDescription"
                  labelSize="full"
                  text={item.description}
                />
              </Col>
            </LabelRow>
          ))}
          <LabelRow>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.additionalInsurance"
                labelSize="full"
                text={
                  <CurrencyFormat
                    value={get(
                      shipment,
                      'serviceInformation.additionalInsurance'
                    )}
                  />
                }
              />
            </Col>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelId="shipmentDetail.declaredValue"
                labelSize="full"
                text={
                  <CurrencyFormat
                    value={get(shipment, 'serviceInformation.declaredValue')}
                  />
                }
              />
            </Col>
          </LabelRow>
        </ShipmentSummaryCardSection>
      </ShipmentSummaryCard>
      {showAirlineFlightStatus && (
        <AirlineFlightStatusCard shipment={shipment} />
      )}
      {typeof get(shipment, 'serviceInformation.dangerousGoods') ===
        'boolean' && <ShipmentDangerousGoodsDeclaration shipment={shipment} />}
    </div>
  );
}
