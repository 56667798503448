import {
  capitalize,
  deburr,
  isArray,
  isNumber,
  isPlainObject,
  isString,
  mapValues,
  upperCase,
} from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

export function normalizeString(str, lang) {
  const nonNullStr = str || '';
  const lCase = lang
    ? nonNullStr.toLocaleLowerCase(lang)
    : nonNullStr.toLowerCase();
  return deburr(lCase);
}

export function stringOr(value, defaultValue) {
  if (isString(value)) {
    return value;
  }
  if (isNumber(value)) {
    return `${value}`;
  }
  return defaultValue;
}

function makePolymporphicFormatter(formatter) {
  const self = text => {
    if (isArray(text)) {
      return text.map(self);
    }
    if (isPlainObject(text)) {
      return mapValues(text, self);
    }
    if (isString(text)) {
      return formatter(text);
    }
    return text;
  };
  return self;
}

export const capitalizeWords = makePolymporphicFormatter(text =>
  text.replace(/([a-zA-Z0-9À-ž]+)/g, (whole, word) => capitalize(word))
);

export const uppercaseWords = makePolymporphicFormatter(upperCase);

export const trimWords = makePolymporphicFormatter(text => text.trim());

// `normalize('NFD')` in combination with `deburr` gives the best results as to the combination
// of output quality and bundle size, but some non-ASCII characters still get through. We handle
// known characters manually and remove all the remaining ones to keep payload clean
// Add as they are discovered
const OTHER_CONVERSIONS = [
  { from: '€', to: 'EUR' },
  // eslint-disable-next-line no-control-regex
  { from: /[^\u0000-\u0080]/g, to: '' },
];
export function sanitizeToAscii(text) {
  return OTHER_CONVERSIONS.reduce(
    (prev, { from, to }) => prev.replace(from, to),
    deburr(text.normalize('NFD'))
  );
}
export const sanitizeFormValueToAscii = makePolymporphicFormatter(
  sanitizeToAscii
);

export function useNodeInnerText() {
  const ref = useRef();
  const [text, setText] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      setText(ref.current.innerText);
    }
  });

  return { ref, text };
}
