import { Col, Drawer, Row, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Whitespace from '../../../components/Whitespace';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../../components/buttons/IconButton';
import { SimpleConfirmDialog } from '../../../components/dialogs/SimpleConfirmDialog';
import { FormItemFieldRegistration } from '../../../components/forms/basicFormElements';
import { useFormContext } from '../../../components/forms/forms';
import { TwoLines } from '../../../components/layout/layoutElements';
import { InlineLink } from '../../../components/typography';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { JobEmailNotificationsForm } from '../../../widgets/emailNotificationsForms';

export function NewShipmentEmailNotificationsButton() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);
  const { values, formInstance, forceUpdate } = useFormContext();
  const formRef = useRef();

  return (
    <>
      <FormItemFieldRegistration name="overrideEmailNotifications" />
      <FormItemFieldRegistration name="notifications" />
      <InlineLink
        textId="book.newShipment.label.addEmailNotifications"
        iconBefore={<FAIcon icon="envelope" className="icon-16" />}
        className="NewShipment-AddEmailNotifications"
        onClick={() => setDrawerOpen(true)}
      />
      <Drawer
        className="ShipmentEmailNotificationsDrawer"
        closable
        title={
          <FormattedMessage id="book.newShipment.label.emailNotifications" />
        }
        closeIcon={
          <IconButton
            icon="times"
            className="CloseButton"
            data-subject="drawer"
            data-action="close"
          />
        }
        destroyOnClose
        mask
        maskClosable
        visible={drawerOpen}
        onClose={() => {
          if (formRef.current.isAdding) {
            setCloseDialogOpen(true);
          } else {
            setDrawerOpen(false);
          }
        }}
      >
        <TwoLines />
        <div className="EmailNotifications spaces-vert-norm">
          <Typography.Title level={4}>
            <FormattedMessage id="settings.emailNotificationSettings.activeNotifications" />
          </Typography.Title>
          <Row align="middle" justify="space-between">
            <Col className="EmailNotifications__OverrideLabel">
              <FormattedMessage
                id={`book.newShipment.label.emailNotifications.${
                  values.override ? 'job' : 'user'
                }`}
              />
              <Whitespace />
              <FormattedMessage id="book.newShipment.label.emailNotifications.shown" />
            </Col>
            <Col className="EmailNotifications__OverrideValue">
              <Tooltip
                title={
                  <FormattedMessage id="book.newShipment.label.emailNotifications.overrideInfo" />
                }
                placement="bottom"
                overlayClassName="ant-tooltip--sm"
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="Clickable">
                  <FormattedMessage id="book.newShipment.label.emailNotifications.override" />
                  <FAIcon
                    icon="toggle-on"
                    className={classNames(
                      'icon-24',
                      values.overrideEmailNotifications
                        ? 'EmailNotifications__OverrideSwitch--On'
                        : 'EmailNotifications__OverrideSwitch--Off'
                    )}
                    onClick={() => {
                      const newVal = !formInstance.getFieldsValue()
                        .overrideEmailNotifications;
                      formInstance.setFieldsValue({
                        overrideEmailNotifications: newVal,
                      });
                      forceUpdate();
                    }}
                  />
                </label>
              </Tooltip>
            </Col>
          </Row>
          <div
            className={classNames('EmailNotifications__Subform', {
              'EmailNotifications__Subform--Disabled': !values.overrideEmailNotifications,
            })}
          >
            <JobEmailNotificationsForm
              ref={formRef}
              onSave={vals => {
                formInstance.setFieldsValue({ notifications: vals });
                forceUpdate();
              }}
              values={values.notifications}
              override={values.overrideEmailNotifications}
              useWrapper={false}
            />
          </div>
        </div>
      </Drawer>
      <SimpleConfirmDialog
        className="size-sm"
        visible={closeDialogOpen}
        textId="book.newShipment.label.emailNotifications.changesUnsaved"
      >
        <Row justify="end" gutter={pxToNumber(cssVariables.spaceNorm2)}>
          <Col>
            <InlineLink
              textId="buttons.cancel"
              onClick={() => setCloseDialogOpen(false)}
              className="InlineLink--Grey"
            />
          </Col>
          <Col>
            <InlineLink
              textId="buttons.discardAndClose"
              onClick={() => {
                setCloseDialogOpen(false);
                setDrawerOpen(false);
              }}
              className="InlineLink--Red"
            />
          </Col>
        </Row>
      </SimpleConfirmDialog>
    </>
  );
}
