import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import { useDialogControls } from '../../common/utils/dialogUtils';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { useStateIfMounted } from '../../utils/reactUtils';
import { FAIcon } from '../adapters/fontAwesomeAdapters';
import { CustomLoaderButton } from '../buttons/buttons';
import { DialogsParentContext } from './DialogsParentContext';
import SimpleDialog from './SimpleDialog';

export function SimpleConfirmDialog({
  className,
  titleId = 'titles.attention',
  title,
  text,
  textId,
  cancelTextId,
  onClose = noop,
  cancelButtonProps,
  cancelColProps,
  okTextId,
  onOk = noop,
  okButtonProps,
  okColProps,
  borderAboveButtons,
  children,
  ...rest
}) {
  const [cancelling, setCancelling] = useStateIfMounted();
  const [confirming, setConfirming] = useStateIfMounted();
  const disabled = cancelling || confirming;
  const showButtonRow = okTextId || cancelTextId;

  return createPortal(
    <SimpleDialog
      className={classNames('SimpleConfirmDialog', className)}
      icon={<FAIcon icon="exclamation-triangle" className="size-sm" />}
      {...rest}
    >
      {(titleId || title) && (
        <Typography.Title level={1}>
          {titleId ? <FormattedMessage id={titleId} /> : title}
        </Typography.Title>
      )}
      {(textId || text) && (
        <Typography.Text className="nl-as-newline">
          {textId ? <FormattedMessage id={textId} /> : text}
        </Typography.Text>
      )}
      {children}
      {showButtonRow && (
        <>
          {borderAboveButtons && (
            <div className="SimpleDialog__ButtonsBorder" />
          )}
          <Row
            gutter={pxToNumber(cssVariables.spaceNorm2)}
            className="SimpleDialog__Buttons"
          >
            {cancelTextId && (
              <Col {...(cancelColProps || { className: 'Flex1' })}>
                <CustomLoaderButton
                  onClick={async e => {
                    e.stopPropagation();
                    setCancelling(true);
                    try {
                      await onClose();
                    } finally {
                      setCancelling(false);
                    }
                  }}
                  ghost
                  disabled={disabled}
                  loading={cancelling}
                  data-subject="simple-dialog"
                  data-action="cancel"
                  {...cancelButtonProps}
                >
                  <FormattedMessage id={cancelTextId} />
                </CustomLoaderButton>
              </Col>
            )}
            {okTextId && (
              <Col {...(okColProps || { className: 'Flex1' })}>
                <CustomLoaderButton
                  type="primary"
                  onClick={async e => {
                    e.stopPropagation();
                    setConfirming(true);
                    try {
                      await onOk();
                    } finally {
                      setConfirming(false);
                    }
                  }}
                  disabled={disabled}
                  loading={confirming}
                  data-subject="simple-dialog"
                  data-action="ok"
                  {...okButtonProps}
                >
                  <FormattedMessage id={okTextId} />
                </CustomLoaderButton>
              </Col>
            )}
          </Row>
        </>
      )}
    </SimpleDialog>,
    document.body
  );
}

export function SimpleConfirmDialogButton({
  className,
  text,
  textId,
  cancelTextId,
  onClose = noop,
  okTextId,
  onOk = noop,
  onOpenChange,
  children,
  ...rest
}) {
  const { setDialogOpen } = useContext(DialogsParentContext);
  const { isOpen, open, close } = useDialogControls({
    onOpenChange: onOpenChange || setDialogOpen,
  });

  return (
    <>
      {children({ open })}
      {
        <SimpleConfirmDialog
          className={className}
          text={text}
          textId={textId}
          cancelTextId={cancelTextId}
          onClose={async () => {
            await onClose();
            close();
          }}
          okTextId={okTextId}
          onOk={async () => {
            await onOk();
            close();
          }}
          visible={isOpen}
          {...rest}
        />
      }
    </>
  );
}
