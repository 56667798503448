import { noop } from 'lodash-es';
import { useRef } from 'react';
import { easeInOutSine } from 'use-easing/lib/easings';

import { useMountAndUpdateEffect } from '../common/utils/hookUtils';
import { cssVariables } from '../styles/cssVariables';
import { animate } from './animateUtils';
import { msToNumber } from './cssUtils';

export function getScrollYParent(element) {
  let parent = element;
  // eslint-disable-next-line no-cond-assign
  while ((parent = parent.parentElement)) {
    const cs = getComputedStyle(parent, null);
    const overflow =
      cs.getPropertyValue('overflow') + cs.getPropertyValue('overflow-y');
    const isScrollable = /(auto|scroll)/.test(overflow);
    if (isScrollable) {
      return parent;
    }
  }

  return null;
}

export function getScrollYOffset(element) {
  const scrollYParent = getScrollYParent(element);
  if (!scrollYParent) {
    return 0;
  }

  const { top: elTop } = element.getBoundingClientRect();
  const { top: contTop } = scrollYParent.getBoundingClientRect();

  return elTop - contTop + scrollYParent.scrollTop;
}

function scrollToElement(element) {
  const scrollYParent = getScrollYParent(element);
  if (!scrollYParent) {
    return { promise: Promise.resolve(), cancel: noop };
  }

  return animate({
    startValue: scrollYParent.scrollTop,
    endValue: getScrollYOffset(element),
    duration: msToNumber(cssVariables.animationDurationLg),
    easing: easeInOutSine,
    onStep: value => {
      if (!element.parentElement) {
        return false;
      }
      scrollYParent.scrollTop = value;
      return undefined;
    },
  });
}

export function isElementInScrollView(element) {
  const scrollYParent = getScrollYParent(element);
  if (!scrollYParent) {
    return false;
  }

  const { top: elTop, bottom: elBottom } = element.getBoundingClientRect();
  const {
    top: contTop,
    bottom: contBottom,
  } = scrollYParent.getBoundingClientRect();

  return elTop >= contTop && elBottom <= contBottom;
}

export function useScrollToThis({ active, skipIfInScrollView }) {
  const ref = useRef();
  useMountAndUpdateEffect(
    {
      onMount() {
        if (active) {
          if (!skipIfInScrollView || !isElementInScrollView(ref.current)) {
            const { cancel } = scrollToElement(ref.current);
            return cancel;
          }
        }
        return noop;
      },
      onUpdate([prevActive]) {
        if (active && !prevActive) {
          if (!skipIfInScrollView || !isElementInScrollView(ref.current)) {
            const { cancel } = scrollToElement(ref.current);
            return cancel;
          }
        }
        return noop;
      },
    },
    [active]
  );
  return ref;
}
