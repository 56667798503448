import { Drawer } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import IconButton from '../buttons/IconButton';

export default function ListEditDrawer({
  children,
  className,
  title,
  titleId,
  isOpen,
  close,
  ...rest
}) {
  return (
    <Drawer
      className={classNames('ListEditDrawer', className)}
      visible={isOpen}
      title={titleId ? <FormattedMessage id={titleId} /> : title}
      closable
      closeIcon={
        <IconButton
          icon="window-close"
          data-subject="drawer"
          data-action="close"
        />
      }
      mask
      maskClosable
      onClose={close}
      footer={null}
      {...rest}
    >
      {children}
    </Drawer>
  );
}
