import { Form } from 'antd';
import { isBoolean, isNil, random } from 'lodash-es';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useMountEffect } from '../../common/utils/hookUtils';
import { FormItemOverrideContext } from './FormItem';
import { FormInterceptorContext, NoLabelForm, useFormContext } from './forms';

function useIntermediateSubformState() {
  const [editing, setEditing] = useState(false);
  const [tmpValues, setTmpValues] = useState();

  return {
    editing,
    setEditing,
    tmpValues,
    onTmpValuesChange: setTmpValues,
  };
}

function UncontrolledWithIntermediateSubformState(props) {
  const state = useIntermediateSubformState();

  return <ControlledWithIntermediateSubformState {...props} {...state} />;
}

function getEffectiveValue({ tmpValues, value }) {
  return tmpValues || value;
}

function useTmpValuesSynchronizer({
  tmpValues,
  value,
  onTmpValuesChange,
  editing,
}) {
  useEffect(() => {
    // When editing and not yet set, we want to put form value into tmpValues
    if (isNil(tmpValues) && editing) {
      onTmpValuesChange(value);
    }
    // When editing is done and tmpValues are still set, we want to unset them
    if (!isNil(tmpValues) && !editing) {
      onTmpValuesChange(null);
    }
  }, [editing, onTmpValuesChange, tmpValues, value]);
}
function FormUpdater({ tmpValues, value }) {
  const { formInstance, forceUpdate } = useFormContext();

  // This component will be mounted anytime edit-mode is activated
  // (either as a transition from non-edit-mode or as a result of node tree change because of responsive design)
  useMountEffect(() => {
    formInstance.resetFields();
    formInstance.setFieldsValue(getEffectiveValue({ tmpValues, value }));
    forceUpdate();
  });

  return null;
}

function ControlledWithIntermediateSubformState({
  renderPreview,
  renderEditing,
  editing,
  setEditing,
  tmpValues,
  onTmpValuesChange,
  disabled: disabledOuter,
  viewMode: viewModeOuter,
  formMode,
  ...rest
}) {
  const overrideContext = useContext(FormItemOverrideContext);
  const disabled = disabledOuter || overrideContext.disabled;
  const viewMode = viewModeOuter || overrideContext.viewMode;

  const { value, onChange, onBlur: onBlurOuter } = rest;
  // Save and cancel are equivalent to blur
  const { onBlur: onBlurGlobal } = useContext(FormInterceptorContext);

  // Some ant components generate id based solely on their name and form name,
  // so this is to prevent clashes
  const formName = useRef(`${random(10000000, 99999999)}`);

  const [subformForm] = Form.useForm();

  useTmpValuesSynchronizer({ editing, onTmpValuesChange, tmpValues, value });

  const startEditing = useCallback(() => {
    setEditing(true);
  }, [setEditing]);
  const saveChanges = useCallback(async () => {
    await subformForm.validateFields();
    onChange(tmpValues);
    setEditing(false);
    onBlurOuter && onBlurOuter();
    onBlurGlobal();
  }, [onBlurGlobal, onBlurOuter, onChange, setEditing, subformForm, tmpValues]);
  const stopEditing = useCallback(() => {
    setEditing(false);
    onTmpValuesChange(null);
    onBlurOuter && onBlurOuter();
    onBlurGlobal();
  }, [onBlurGlobal, onBlurOuter, onTmpValuesChange, setEditing]);

  const onValuesChange = useCallback((changed, all) => onTmpValuesChange(all), [
    onTmpValuesChange,
  ]);

  return editing && !disabled && !viewMode ? (
    <NoLabelForm
      form={subformForm}
      name={formName.current}
      component={false}
      onValuesChange={onValuesChange}
      // This is redundant to the logic in `FormUpdater`, but mount effect there is called a little bit later,
      // which may result in a moment of empty arrays, forcing auto-creation of initial items
      initialValues={getEffectiveValue({ tmpValues, value })}
      mode={formMode}
    >
      <FormUpdater value={value} tmpValues={tmpValues} />
      {renderEditing({ saveChanges, stopEditing, subformForm, ...rest })}
    </NoLabelForm>
  ) : (
    <Form form={subformForm} component={false}>
      {renderPreview({
        startEditing,
        subformForm,
        disabled,
        viewMode,
        ...rest,
      })}
    </Form>
  );
}

function hasIntermediateSubformState(props) {
  return (
    isBoolean(props.editing) && props.setEditing && props.onTmpValuesChange
  );
}
export function withIntermediateSubformState({ renderPreview, renderEditing }) {
  return props =>
    hasIntermediateSubformState(props) ? (
      <ControlledWithIntermediateSubformState
        {...props}
        renderPreview={renderPreview}
        renderEditing={renderEditing}
      />
    ) : (
      <UncontrolledWithIntermediateSubformState
        {...props}
        renderPreview={renderPreview}
        renderEditing={renderEditing}
      />
    );
}
