import { Input } from 'antd';
import classNames from 'classnames';
import { isArray, negate, range, some, stubTrue } from 'lodash-es';
import { useCallback, useRef } from 'react';
import { findDOMNode } from 'react-dom';

import { useMountEffect } from '../../common/utils/hookUtils';
import { FormItem } from './FormItem';
import { extractValueFromEvent } from './formHelpers';

function getFocusable(node, inputType, newValue) {
  if (inputType === 'insertText' && newValue) {
    return node.nextElementSibling;
  }
  if (inputType === 'deleteContentBackward') {
    return node.previousElementSibling;
  }
  return null;
}

function focusByAction(node, inputType, newValue) {
  const focusable = getFocusable(node, inputType, newValue);
  if (focusable) {
    focusable.focus();
    focusable.select();
  }
}

function LetterInputPart({ value, onChange, index, className, ...rest }) {
  const ref = useRef();
  const val = (value || [])[index];
  const onChangeThis = useCallback(
    e => {
      const newVal = (extractValueFromEvent(e) || '').slice(0, 1);
      const newValue = [...(value || [])];
      newValue[index] = newVal;
      onChange(newValue);

      // eslint-disable-next-line react/no-find-dom-node
      const node = findDOMNode(ref.current);
      const { inputType } = e.nativeEvent;
      focusByAction(node, inputType, newVal);
    },
    [index, onChange, value]
  );

  return (
    <Input
      ref={ref}
      className={classNames('InputText LetterInput__Letter', className)}
      value={val}
      onChange={onChangeThis}
      {...rest}
      size={1}
    />
  );
}

function LetterInput({
  size = 1,
  elementSize = 'md',
  id,
  name,
  className,
  letterClassName,
  isLetterAllowed = stubTrue,
  value,
  onChange: onChangeOuter,
  autoFocus,
  ...rest
}) {
  const firstProps = { autoFocus };

  // Sanitizing on change
  const onChange = useCallback(
    val => {
      // Disallow non-array values (for example browser may try to autocomplete string)
      if (!isArray(val)) {
        onChangeOuter([]);
      }
      // Trim long values
      else if (val.length > size) {
        onChangeOuter(val.slice(0, size));
      }
      // Remove unallowed letters
      else if (some(val, negate(isLetterAllowed))) {
        onChangeOuter(
          val.map(letter => (isLetterAllowed(letter) ? letter : null))
        );
      } else {
        onChangeOuter(val);
      }
    },
    [isLetterAllowed, onChangeOuter, size]
  );

  // HACK: autocomplete=off is ignored by browser
  // Sanitize initial value (possibly set by browser autocomplete)
  useMountEffect(() => {
    onChange(value);
  });

  return (
    <div
      id={id}
      data-name={name}
      className={classNames(
        'LetterInput',
        `LetterInput--size-${elementSize}`,
        className
      )}
    >
      {range(0, size).map(i => (
        <LetterInputPart
          key={i}
          value={value}
          onChange={onChange}
          index={i}
          className={letterClassName}
          {...rest}
          {...(i === 0 ? firstProps : {})}
        />
      ))}
    </div>
  );
}

export function FormItemLetterInput({
  size,
  elementSize,
  placeholder,
  formItemComponentProps,
  ...rest
}) {
  return (
    <FormItem
      floatingLabel={false}
      {...rest}
      formItemComponentProps={{
        size,
        elementSize,
        ...formItemComponentProps,
      }}
      component={LetterInput}
    />
  );
}
