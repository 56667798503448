import { Popover } from 'antd';
import classNames from 'classnames';
import { flatMap, noop, some } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useMultiElementFocus,
  useStateIfMounted,
} from '../../utils/reactUtils';
import { useScrollToThis } from '../../utils/scrollUtils';
import Whitespace from '../Whitespace';
import { FAIcon } from '../adapters/fontAwesomeAdapters';
import { ThreeDotsSpinner } from '../data/Spinner';
import { DataError } from '../data/dataStateHandlers';
import { useFlashMessageContext } from '../dialogs/FlashMessageProvider';
import AutoHeightScrollbars from '../layout/AutoHeightScrollbars';
import Collapsible from '../layout/Collapsible';
import { InlineLink } from '../typography';
import { FormItem } from './FormItem';
import { FormItemInputText } from './basicFormElements';
import { extractValueFromEvent } from './formHelpers';

function Item({ active, label, onClick, loading, disabled }) {
  const ref = useScrollToThis({ active, skipIfInScrollView: true });
  return (
    <div
      ref={ref}
      className={classNames('Clickable', { active, disabled })}
      title={label}
      onClick={disabled ? noop : onClick}
    >
      {label}
      {loading && (
        <>
          <Whitespace />
          <ThreeDotsSpinner />
        </>
      )}
    </div>
  );
}

function GroupContent({
  loading,
  error,
  options,
  activeItem,
  applying,
  onSelect,
}) {
  if (loading) {
    return (
      <div>
        <ThreeDotsSpinner />
      </div>
    );
  }
  if (error) {
    return <DataError error={error} />;
  }

  return options.map(opt => (
    <Item
      key={opt.value}
      active={opt.value === activeItem}
      loading={opt.value === applying}
      disabled={!!applying}
      label={opt.label}
      onClick={() => onSelect(opt)}
    />
  ));
}

function Group({
  header,
  headerId,
  loading,
  error,
  options,
  activeItem,
  applying,
  onSelect,
}) {
  const [open, setOpen] = useState(true);

  const hasActive = some(options, item => item.value === activeItem);
  // Open group when the item in it was set active
  useEffect(() => {
    if (hasActive && !open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  if (!header && !headerId) {
    return (
      <div className="CustomGroupAutocomplete-Group">
        <GroupContent
          loading={loading}
          error={error}
          options={options}
          activeItem={activeItem}
          applying={applying}
          onSelect={onSelect}
        />
      </div>
    );
  }

  return (
    <div className="CustomGroupAutocomplete-Group">
      <div className="CustomGroupAutocomplete-GroupHeader">
        <InlineLink
          iconBefore={<FAIcon icon={open ? 'caret-down' : 'caret-right'} />}
          onClick={e => setOpen(!open)}
        >
          {headerId ? <FormattedMessage id={headerId} /> : header}
        </InlineLink>
      </div>
      <Collapsible expanded={open} dynamicContent>
        <GroupContent
          loading={loading}
          error={error}
          options={options}
          activeItem={activeItem}
          applying={applying}
          onSelect={onSelect}
        />
      </Collapsible>
    </div>
  );
}

function useKeyControls({ options, onSelect }) {
  const [activeItem, setActiveItem] = useState();
  const flatOptions = flatMap(options, group => group.options);

  const applyActive = async () => {
    const activeItemObj = flatOptions.find(item => item.value === activeItem);
    if (activeItemObj) {
      await onSelect(activeItemObj);
    }
  };
  const moveActive = diff => {
    const index = flatOptions.findIndex(item => item.value === activeItem);
    const newIndex =
      index === -1
        ? 0
        : (index + diff + flatOptions.length) % flatOptions.length;
    setActiveItem(flatOptions[newIndex].value);
  };
  const clearActive = () => setActiveItem(undefined);

  const onKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown':
        moveActive(1);
        e.preventDefault();
        break;
      case 'ArrowUp':
        moveActive(-1);
        e.preventDefault();
        break;
      case 'Enter':
        applyActive();
        e.preventDefault();
        break;
      default:
    }
  };

  return {
    activeItem,
    clearActive,
    onKeyDown,
  };
}

function CustomGroupAutocomplete({
  name,
  'data-name': originalName,
  className,
  options,
  onSelect: onSelectOuter,
  onSearch,
  formInputProps,
  value,
  onChange,
  onFocus: onFocusOuter,
  onBlur: onBlurOuter,
  setLabelOnSelect = true,
}) {
  const { errorMessage } = useFlashMessageContext();
  const triggerRef = useRef();

  const {
    focused: isOpen,
    onFocus,
    onBlur,
    forceBlur: close,
  } = useMultiElementFocus({
    onFocus: onFocusOuter,
    onBlur: () => {
      clearActive();
      onBlurOuter();
    },
  });
  const [applying, setApplying] = useStateIfMounted();

  const onSelect = async opt => {
    try {
      setApplying(opt.value);
      if (onSelectOuter) {
        await onSelectOuter(opt);
      }
    } catch (e) {
      errorMessage(e);
    } finally {
      setApplying(undefined);
      if (setLabelOnSelect) {
        onChange(opt.label);
      }
      close();
    }
  };

  const { activeItem, clearActive, onKeyDown } = useKeyControls({
    options,
    onSelect,
  });

  return (
    <Popover
      className={className}
      overlayClassName="CustomGroupAutocomplete-Popup no-arrow"
      overlayStyle={
        // Popover doesn't have a functionality to match the trigger width
        // and relative container doesn't work because of overflow, so
        // we have to measure it and set it manually
        triggerRef.current ? { width: triggerRef.current.clientWidth } : {}
      }
      align={{
        points: ['tl', 'bl'],
        offset: [0, 0],
        overflow: {
          adjustX: 1,
          adjustY: 1,
        },
      }}
      visible={isOpen}
      content={
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          htmlFor={name}
        >
          <AutoHeightScrollbars name={name} className="Theme-Light">
            {options.map(group => (
              <Group
                key={group.groupId}
                activeItem={activeItem}
                applying={applying}
                onSelect={onSelect}
                {...group}
              />
            ))}
          </AutoHeightScrollbars>
        </div>
      }
    >
      <div ref={triggerRef}>
        <FormItemInputText
          {...formInputProps}
          name={originalName}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          formItemComponentProps={{
            value,
            onChange: e => {
              onChange(e);
              onSearch && onSearch(extractValueFromEvent(e));
            },
          }}
        />
      </div>
    </Popover>
  );
}

export function FormItemCustomGroupAutocomplete({
  options,
  formItemComponentProps,
  name,
  rules,
  ...rest
}) {
  return (
    <FormItem
      options={options}
      name={name}
      rules={rules}
      formItemComponentProps={{
        ...formItemComponentProps,
        formInputProps: rest,
      }}
      component={CustomGroupAutocomplete}
    />
  );
}
