import { Tooltip } from 'antd';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFormContext } from '../../../components/forms/forms';
import { FormItemSchemaDefinedSelect } from '../../../components/forms/selects';
import { SavedShipmentContext } from '../newShipment/SavedShipmentProvider';
import { SAVED_SHIPMENT_SERVICE_TYPE } from '../newShipment/savedShipmentsUtils';

function ServiceTypeWrapper({ forceTbdServiceType, children }) {
  if (!forceTbdServiceType) {
    return children;
  }
  return (
    <Tooltip title={<FormattedMessage id="book.newShipment.serviceType.tbd" />}>
      <div>{children}</div>
    </Tooltip>
  );
}

export function ServiceTypeField() {
  // This will be extracted from the available options (we know the code but not the text)
  const serviceTypeSavedShipment = useRef({
    value: {
      code: SAVED_SHIPMENT_SERVICE_TYPE,
      text: '', // Placeholder text
    },
  });

  const { enabled: savedShipmentEnabled, forceTbdServiceType } = useContext(
    SavedShipmentContext
  );
  const { values, formInstance, forceUpdate } = useFormContext();
  const currentValue = values.serviceType;

  useEffect(() => {
    // It has to be set to this in "saved shipment" mode, so we force it
    if (
      forceTbdServiceType &&
      currentValue?.code !== SAVED_SHIPMENT_SERVICE_TYPE
    ) {
      formInstance.setFieldsValue({
        serviceType: serviceTypeSavedShipment.current.value,
      });
      forceUpdate();
    }

    // If saved shipment is not enabled, this option will be unset, since it's not available
    if (
      !savedShipmentEnabled &&
      currentValue?.code === SAVED_SHIPMENT_SERVICE_TYPE
    ) {
      formInstance.setFieldsValue({
        serviceType: null,
      });
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, formInstance, forceTbdServiceType]);

  const mapOptions = useCallback(
    options =>
      // Remove "TBD" type if saved shipment mode is not enabled
      savedShipmentEnabled
        ? options
        : options.filter(o => o.value?.code !== SAVED_SHIPMENT_SERVICE_TYPE),
    [savedShipmentEnabled]
  );
  const withOptions = useCallback(
    options => {
      const needsUpdate =
        currentValue &&
        currentValue.code === SAVED_SHIPMENT_SERVICE_TYPE &&
        !currentValue.text;

      // Find and store "TBD" type to be able to set it (also text is needed for setting value)
      const opt = options.find(
        o => o.value?.code === SAVED_SHIPMENT_SERVICE_TYPE
      );
      if (opt) {
        serviceTypeSavedShipment.current = opt;
        if (needsUpdate) {
          formInstance.setFieldsValue({
            serviceType: opt.value,
          });
          forceUpdate();
        }
      } else {
        serviceTypeSavedShipment.current = { value: null };
        if (needsUpdate) {
          formInstance.setFieldsValue({
            serviceType: null, // "TBD" option is not available
          });
          forceUpdate();
        }
      }
    },
    [currentValue, forceUpdate, formInstance]
  );

  return (
    <ServiceTypeWrapper forceTbdServiceType={forceTbdServiceType}>
      <FormItemSchemaDefinedSelect
        name="serviceType"
        schemaName="serviceInformation.serviceType"
        labelId="book.newShipment.label.serviceType"
        mapOptions={mapOptions}
        withOptions={withOptions}
        disabled={forceTbdServiceType}
        preloadOptions
      />
    </ServiceTypeWrapper>
  );
}
