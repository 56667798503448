import { Drawer } from 'antd';
import classNames from 'classnames';

import IconButton from '../buttons/IconButton';

export default function ShipmentDetailDrawer({
  className,
  visible,
  children,
  onClose,
  header,
  leftHeader,
  rightHeader,
}) {
  return (
    <Drawer
      className={classNames('ShipmentDetailDrawer', className)}
      closable
      title={header}
      closeIcon={
        <IconButton
          icon="times"
          className="CloseButton"
          data-subject="drawer"
          data-action="close"
        />
      }
      destroyOnClose
      mask
      maskClosable
      visible={visible}
      onClose={onClose}
    >
      {leftHeader && <div className="TopLeftHeader">{leftHeader}</div>}
      {rightHeader && <div className="TopRightHeader">{rightHeader}</div>}
      {children}
    </Drawer>
  );
}
