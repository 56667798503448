import { useQuery } from '@apollo/client';
import { Col, Row, Tooltip } from 'antd';
import { get, property } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { USER_QUERY } from '../../../app/graphql/authQueries';
import { formatCodeValue } from '../../../common/utils/formatUtils';
import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { FATypes } from '../../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../../components/buttons/IconButton';
import { Spinner } from '../../../components/data/Spinner';
import { FormItemInputCurrency } from '../../../components/domainSpecific/currencyElements';
import FormList from '../../../components/forms/FormList';
import {
  FormItemFieldRegistration,
  FormItemInputText,
} from '../../../components/forms/basicFormElements';
import { FormItemYesNo } from '../../../components/forms/checkable';
import DynamicFieldsForm from '../../../components/forms/dynamic/DynamicFieldsForm';
import {
  useDynamicFormSchemaDynamicFieldsDefinition,
  useDynamicFormSchemaFieldDefinition,
} from '../../../components/forms/dynamic/dynamicFormSchema';
import { useDynamicFormValidationRules } from '../../../components/forms/dynamic/dynamicFormValidation';
import { useFormListCallbacks } from '../../../components/forms/formDecorators';
import { EnsureInitialFormListItem } from '../../../components/forms/formHelpers';
import { numericRule } from '../../../components/forms/formValidationRules';
import { useFormContext } from '../../../components/forms/forms';
import { FormItemSchemaDefinedSelect } from '../../../components/forms/selects';
import { ConfigurableCard } from '../../../components/layout/cardElements';
import {
  FlexCol,
  TwoLines,
  TwoLinesSeparator,
} from '../../../components/layout/layoutElements';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { DividerCol } from '../packageList/packageListCommon';
import { ServiceTypeField } from '../shipmentFormsCommon/shipmentServiceInformationFields';
import { useNewOrderFeatures } from '../shipmentModes';
import { NewShipmentEmailNotificationsButton } from './NewShipmentEmailNotifications';
import { NewShipmentBaseRow, NewShipmentForm } from './newShipmentElements';

const TopRowCol = ({ children, className, ...rest }) => (
  <Col className="NewShipment-ServiceInformation-TopCol " {...rest}>
    {children}
  </Col>
);

const SECOND_CARD_DYN_PROPS = {
  schemaName: 'serviceInformation.shipperSpecificInfo',
  namePrefix: 'shipperSpecificInfo',
  rowComponent: NewShipmentBaseRow,
  colComponent: TopRowCol,
};
function SecondCard() {
  const fields = useDynamicFormSchemaDynamicFieldsDefinition({
    schemaName: SECOND_CARD_DYN_PROPS.schemaName,
  });

  return (
    fields.length > 0 && (
      <ConfigurableCard
        titleId="book.newShipment.shipperSpecificInformation"
        bodyComponent="div"
        bodyClassName="NewShipmentCardBody spaces-vert-norm"
      >
        <DynamicFieldsForm {...SECOND_CARD_DYN_PROPS} />
      </ConfigurableCard>
    )
  );
}

const BolNumber = () => (
  <FormItemInputText
    name="bolNumber"
    schemaName="serviceInformation.bolNumber"
    labelId="book.newShipment.label.hawbBol"
  />
);
const CostCenter = () => (
  <FormItemInputText
    name="costCenter"
    schemaName="serviceInformation.costCenter"
    labelId="book.newShipment.label.costCenter"
  />
);
const DeclaredValue = () => (
  <FormItemInputCurrency
    name="declaredValue"
    schemaName="serviceInformation.declaredValue"
    labelId="book.newShipment.label.declaredValue"
    rules={schemaRules => [...schemaRules, numericRule]}
  />
);
const AdditionalInsurance = () => (
  <FormItemInputCurrency
    name="additionalInsurance"
    schemaName="serviceInformation.additionalInsurance"
    labelId="book.newShipment.label.additionalInsurance"
    rules={schemaRules => [...schemaRules, numericRule]}
  />
);
const EoriNumber = () => (
  <Tooltip
    title={<FormattedMessage id="book.newShipment.label.eoriNumber.tooltip" />}
    overlayClassName="ant-tooltip--sm"
    placement="topLeft"
  >
    <div>
      <FormItemInputText
        name="eoriNumber"
        schemaName="serviceInformation.eoriNumber"
        labelId="book.newShipment.label.eoriNumber"
      />
    </div>
  </Tooltip>
);
function HsInfoItem({ fields, index, add, remove, move, children }) {
  const { values } = useFormContext();
  const { addAfter, removeThis } = useFormListCallbacks({
    fields,
    index,
    add,
    remove,
    move,
  });
  const currentValue = get(values, ['hsInfo', index]);

  return (
    <Row align="middle" gutter={pxToNumber(cssVariables.spaceNorm2)}>
      <Col>
        <FlexCol
          align="center"
          justify="space-around"
          className="PieceList-Controls"
        >
          <IconButton
            icon="trash-alt"
            onClick={removeThis}
            titleId="buttons.delete"
            data-subject="piece-list"
            data-action="delete"
          />
          <IconButton
            icon="clone"
            onClick={() => addAfter(currentValue)}
            titleId="buttons.copy"
            data-subject="piece-list"
            data-action="copy"
          />
          <IconButton
            icon="plus-square"
            onClick={addAfter}
            titleId="buttons.add"
            data-subject="piece-list"
            data-action="add-after"
          />
        </FlexCol>
      </Col>
      <Col className="Flex1 PieceList-Envelope">
        <NewShipmentBaseRow align="top">
          <Col style={{ width: `calc(50% - ${cssVariables.spaceNorm2})` }}>
            <Tooltip
              title={
                <FormattedMessage id="book.newShipment.label.hsCode.tooltip" />
              }
              overlayClassName="ant-tooltip--sm"
              placement="topLeft"
            >
              <div>
                <FormItemInputText
                  name={[index, 'code']}
                  schemaName="serviceInformation.hsInfo.code"
                  labelId="book.newShipment.label.hsCode"
                />
              </div>
            </Tooltip>
          </Col>
          <Col className="Flex1">
            <FormItemInputText
              name={[index, 'description']}
              schemaName="serviceInformation.hsInfo.description"
              labelId="book.newShipment.label.hsDescription"
            />
          </Col>
        </NewShipmentBaseRow>
      </Col>
    </Row>
  );
}
const HsInfo = () => (
  <div className="spaces-vert-norm">
    <FormList name="hsInfo">
      {(fields, { add, remove, move }) => (
        <>
          <EnsureInitialFormListItem fields={fields} add={add} value={{}} />
          {(fields || []).map(({ key, name }, index) => (
            <HsInfoItem {...{ fields, add, remove, move, key, name, index }} />
          ))}
        </>
      )}
    </FormList>
  </div>
);

function DGYesNo({ name, schemaName, labelId, ...rest }) {
  const { validation } = useDynamicFormSchemaFieldDefinition({ schemaName });
  const rules = useDynamicFormValidationRules({ schemaName, validation });

  return (
    <div className="NewShipment-DGYesNo" {...rest}>
      <div>
        <FormattedMessage id={labelId} />
        {validation?.rules?.required && ' *'}
      </div>
      <FormItemYesNo
        name={name}
        className="size-lg text-uppercase"
        rules={rules}
      />
    </div>
  );
}

const CODE_AS_LABEL = property('code');
const UNNumberSearch = ({ inputClassName, index }) => (
  <FormItemSchemaDefinedSelect
    className={inputClassName}
    name={index}
    labelId="book.newShipment.label.unNumber"
    schemaName="serviceInformation.unNumber"
    formItemComponentProps={{ formatLabel: CODE_AS_LABEL }}
  />
);
function UNDescriptionPreview({ index }) {
  const { values } = useFormContext();
  return (
    <div className="NewShipment-UNDescription flex-start">
      <div className="text">{formatCodeValue(values?.unNumber[index])}</div>
    </div>
  );
}
function UNNumberRow({ fields, add, remove, move, index, isLast }) {
  const { addAfter, removeThis } = useFormListCallbacks({
    fields,
    index,
    add,
    remove,
    move,
  });

  const dividerCss = isLast ? '' : 'absolute-full';
  return (
    <Col span={24}>
      <Row className="NewShipment-DGStepperRow">
        <Col span={12} className="col-1">
          <FlexCol
            style={{ height: cssVariables['input-height-md'] }}
            justify="space-between"
          >
            <IconButton
              icon="times-circle"
              type={FATypes.SOLID}
              className="DangerousGoodsIcon"
              onClick={removeThis}
              data-subject="device-addons"
              data-action="delete"
            />
            <IconButton
              icon="plus-square"
              type={FATypes.SOLID}
              className="DangerousGoodsIcon"
              onClick={addAfter}
              data-subject="device-addons"
              data-action="add-after"
            />
          </FlexCol>
          <UNNumberSearch index={index} />
        </Col>
        <DividerCol className={dividerCss} />
        <Col span={12} className="col-2">
          <UNDescriptionPreview index={index} />
        </Col>
      </Row>
    </Col>
  );
}

function dangerousGoodsList(fields, { add, remove, move }) {
  return (
    <div>
      <EnsureInitialFormListItem fields={fields || []} add={add} value={{}} />
      <Col span={24}>
        <Row>
          <Col offset={12} span={12}>
            <div className="label">
              <FormattedMessage id="book.newShipment.label.unDescription" />
            </div>
          </Col>
        </Row>
      </Col>
      <div className="spaces-vert-norm">
        {(fields || []).map(({ name, key }, index) => (
          <UNNumberRow
            fields={fields}
            key={key}
            name={name}
            add={add}
            remove={remove}
            move={move}
            index={index}
            isLast={index === (fields || []).length - 1}
          />
        ))}
      </div>
    </div>
  );
}

function DangerousGoodsSection() {
  const { values } = useFormContext();
  const { dangerousGoods } = useNewOrderFeatures();
  const media = useResponsiveQueries();

  const formItemsReg = (
    <>
      <FormItemFieldRegistration name="dangerousGoods" />
      <FormItemFieldRegistration name="exceptedQuantity" />
      <FormItemFieldRegistration name="unNumber" />
    </>
  );

  if (!dangerousGoods) {
    return formItemsReg;
  }

  const showStep2 = values.dangerousGoods;

  return (
    <>
      {formItemsReg}
      <div className="NewShipment-ServiceInformation-SectionDivider">
        <TwoLines className="size-10" />
        <div className="NewShipment-ServiceInformation-SectionDividerTitle">
          <FormattedMessage id="book.newShipment.dangerousGoodsDeclaration" />
        </div>
      </div>
      <Row
        className="NewShipment-DGStepperRow no-margin-form-items"
        align="top"
        justify="space-between"
      >
        <Col span={12} className="col-1">
          <DGYesNo
            labelId="book.newShipment.label.dangerousGoods"
            name="dangerousGoods"
            schemaName="serviceInformation.dangerousGoods"
          />
        </Col>
        {showStep2 && (
          <>
            {!media.xs && <DividerCol />}
            <Col span={12} className="col-2">
              <DGYesNo
                labelId="book.newShipment.label.exceptedQuantity"
                name="exceptedQuantity"
                schemaName="serviceInformation.exceptedQuantity"
              />
            </Col>
          </>
        )}
        {showStep2 && (
          <>
            <Col span={24}>
              <TwoLinesSeparator />
            </Col>
            <Col span={24}>
              <FormList name="unNumber">{dangerousGoodsList}</FormList>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export function ServiceInformationCard() {
  return (
    <ConfigurableCard
      titleId="book.newShipment.serviceInformation"
      bodyComponent="div"
      bodyClassName="NewShipmentCardBody NewShipment-ServiceInformationCard"
    >
      <NewShipmentBaseRow>
        <TopRowCol>
          <ServiceTypeField />
        </TopRowCol>
        <TopRowCol>
          <FormItemSchemaDefinedSelect
            name="commodity"
            schemaName="serviceInformation.commodity"
            labelId="book.newShipment.label.commodity"
            allowFreeText
            textAsValue
          />
        </TopRowCol>
      </NewShipmentBaseRow>
      <DangerousGoodsSection />
    </ConfigurableCard>
  );
}

export function ReferenceInformationCard({ showJobSpecificFields = true }) {
  const { data, loading } = useQuery(USER_QUERY);
  const user = data && extractGraphqlEntity(data);

  return loading ? (
    <Spinner />
  ) : (
    <ConfigurableCard
      titleId="book.newShipment.referenceInformation"
      bodyComponent="div"
      bodyClassName="NewShipmentCardBody"
    >
      <NewShipmentBaseRow>
        {showJobSpecificFields && (
          <Col span={12}>
            <BolNumber />
          </Col>
        )}
        <Col span={12}>
          <DeclaredValue />
        </Col>
        <Col span={12}>
          <CostCenter />
        </Col>
        <Col span={12}>
          <AdditionalInsurance />
        </Col>
        {showJobSpecificFields && (
          <Col span={12}>
            <EoriNumber />
          </Col>
        )}
        {showJobSpecificFields && (
          <Col span={24}>
            <HsInfo />
          </Col>
        )}
        {showJobSpecificFields && user.emailNotificationsEnabled && (
          <Col span={24}>
            <NewShipmentEmailNotificationsButton />
          </Col>
        )}
      </NewShipmentBaseRow>
    </ConfigurableCard>
  );
}

export default function NewShipmentServiceInformationForm({ form, ...rest }) {
  return (
    <NewShipmentForm
      {...rest}
      form={form}
      name="serviceInformation"
      className="margins-bottom-norm2"
    >
      <ServiceInformationCard />
      <ReferenceInformationCard />
      <SecondCard form={form} />
    </NewShipmentForm>
  );
}
