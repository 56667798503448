import { Button, Col, Row, Tag } from 'antd';
import classNames from 'classnames';
import {
  identity,
  isEmpty,
  noop,
  property,
  stubFalse,
  toPairs,
} from 'lodash-es';
import { createContext, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router';

import { SortOrder, makeSort } from '../../../app/common/sort';
import { useDebouncedVariable } from '../../../common/utils/hookUtils';
import Whitespace from '../../../components/Whitespace';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import {
  useClearCacheOnVariablesChange,
  usePaginatedQuery,
} from '../../../components/data/dataLoaders';
import { InfiniteScrollList } from '../../../components/data/dataRenderers';
import { DataError } from '../../../components/data/dataStateHandlers';
import { FormItemInputText } from '../../../components/forms/basicFormElements';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../components/forms/formTheme';
import { NoLabelForm, useFormContext } from '../../../components/forms/forms';
import { FormItemDropdownSelect } from '../../../components/forms/selects';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionLine,
  AdminConsoleSectionTableHeader,
} from '../components/AdminConsoleSection';
import AdminConsoleSorter from '../components/AdminConsoleSorter';
import AdminConsoleContent from '../layout/AdminConsoleContent';

function FilterTags({ renderTag }) {
  const { values, formInstance, forceUpdate } = useFormContext();
  const { filterBy } = values;

  return (
    <Row align="middle" gutter={pxToNumber(cssVariables.spaceNorm2)}>
      <Col className="AdminConsoleList__FilterLabel">
        <span className="text-uppercase">
          <FormattedMessage id="admin.commonList.filter" />
        </span>
        {!filterBy && (
          <>
            <Whitespace />
            <FormattedMessage id="admin.commonList.filter.empty" />
          </>
        )}
      </Col>
      {filterBy && (
        <Col>
          <Tag
            className="ant-tag--DarkTheme"
            closable
            closeIcon={<FAIcon icon="times-circle" className="icon-15" />}
            onClose={() => {
              formInstance.setFieldsValue({ filterBy: undefined });
              forceUpdate();
            }}
          >
            {renderTag({ filterBy })}
          </Tag>
        </Col>
      )}
    </Row>
  );
}

function filterToForm({ isActive, ...rest } = {}) {
  let filterBy;
  let searchTerm;
  const restPairs = toPairs(rest);
  if (restPairs.length === 1) {
    const [[key, val]] = restPairs;
    filterBy = key;
    if (filterBy === 'search') {
      filterBy = undefined;
    }
    searchTerm = val;
  }

  return { isActive, filterBy, searchTerm };
}

function formToFilter(values) {
  return {
    [values.filterBy || 'search']: values.searchTerm,
    isActive: values.isActive,
  };
}

export function AdminConsoleListFiltersSection({
  filterByOptions,
  activeOptions,
  defaultActiveOption,
  renderTag,
  placeholderId = 'admin.commonList.search.placeholder',
}) {
  const { filter, setFilter } = useContext(AdminConsoleListContext);

  return (
    <NoLabelForm
      onValuesChange={(changed, values) => {
        setFilter(formToFilter(values));
      }}
      initialValues={filterToForm(filter)}
    >
      <FormThemeOverrideContextProvider themeClassName={FormTheme.DarkSimple}>
        <AdminConsoleSection>
          <AdminConsoleSectionContent>
            <Row
              align="middle"
              gutter={pxToNumber(cssVariables.spaceNorm2)}
              className="no-margin-form-items"
            >
              <Col className="Flex1">
                <FormItemInputText
                  name="searchTerm"
                  formItemComponentProps={{
                    prefix: <FAIcon icon="search" className="icon-18" />,
                    allowClear: true,
                  }}
                  placeholderId={placeholderId}
                  floatingLabel={false}
                />
              </Col>
              <Col>
                <FormItemDropdownSelect
                  name="filterBy"
                  options={filterByOptions}
                  renderLabel={() => (
                    <Button
                      className="AdminConsole__ButtonDropdownTrigger width-160"
                      size="large"
                      icon={<FAIcon icon="filter" className="icon-15" />}
                    >
                      <span>
                        <FormattedMessage id="admin.commonList.applyFilters" />
                      </span>
                    </Button>
                  )}
                  formItemComponentProps={{
                    overlayClassName: 'DropdownTheme--Dark DropdownSize--Large',
                  }}
                />
              </Col>
            </Row>
          </AdminConsoleSectionContent>
          <AdminConsoleSectionLine />
          <AdminConsoleSectionContent className="AdminConsoleList__FilterSectionContent">
            <Row align="middle" justify="space-between">
              <Col>
                <FilterTags renderTag={renderTag} />
              </Col>
              <Col className="AdminConsoleList__BorderlessSelectCol no-margin-form-items">
                <FormItemDropdownSelect
                  name="isActive"
                  options={activeOptions}
                  renderLabel={({ labelId }) => (
                    <Button
                      className="AdminConsole__ButtonDropdownTrigger AdminConsole__ButtonDropdownTrigger--Borderless"
                      size="large"
                    >
                      <span>
                        <FormattedMessage
                          id={labelId || defaultActiveOption.label}
                        />
                      </span>
                    </Button>
                  )}
                  formItemComponentProps={{
                    overlayClassName: 'DropdownTheme--Dark DropdownSize--Large',
                  }}
                />
              </Col>
            </Row>
          </AdminConsoleSectionContent>
        </AdminConsoleSection>
      </FormThemeOverrideContextProvider>
    </NoLabelForm>
  );
}

function Column({ className, children }) {
  return (
    <Col className={classNames('AdminConsoleList__Column', className)}>
      {children}
    </Col>
  );
}

function HeaderSort({ sortName }) {
  const { sort, setSort } = useContext(AdminConsoleListContext);
  return (
    <AdminConsoleSorter sortName={sortName} sort={sort} setSort={setSort} />
  );
}

function HeaderColumn({ className, labelId, sortName }) {
  return (
    <Column className={className}>
      <div className="AdminConsoleList__HeaderColumn">
        <span>
          <FormattedMessage id={labelId} />
        </span>
        {sortName && <HeaderSort sortName={sortName} />}
      </div>
    </Column>
  );
}

function DataTableHeader({ headerColumns, columnClassName }) {
  return (
    <Row gutter={pxToNumber(cssVariables.spaceXs)}>
      {headerColumns.map(({ key, labelId, sortName }) => (
        <HeaderColumn
          key={key}
          className={columnClassName}
          labelId={labelId}
          sortName={sortName}
        />
      ))}
    </Row>
  );
}

function DataTableRow({ value, getLink, renderColumns, columnClassName }) {
  const { push } = useHistory();
  return (
    <div
      className="AdminConsoleList__TableRow Clickable"
      onClick={() => push(getLink(value))}
    >
      <Row align="middle" gutter={pxToNumber(cssVariables.spaceXs)}>
        {renderColumns(value).map(({ key, label }) => (
          <Column key={key} className={columnClassName}>
            {label}
          </Column>
        ))}
      </Row>
    </div>
  );
}

function DataTableBody({
  getKey,
  getRowLink,
  renderColumns,
  rowColumnClassName,
}) {
  const { rows, loading, error, skip, hasNext, loadNext } = useContext(
    AdminConsoleListContext
  );

  if (error) {
    return <DataError error={error} />;
  }

  if (skip) {
    return (
      <AdminConsoleSectionContent>
        <div className="AdminConsoleList__TableBody--Empty AdminConsoleList__TableBody--Empty--NoFilter">
          <FormattedMessage id="admin.commonList.noFilter" />
        </div>
      </AdminConsoleSectionContent>
    );
  }

  return (
    <InfiniteScrollList
      rows={rows}
      hasNext={hasNext}
      loadNext={loadNext}
      error={error}
      loading={loading}
      render={({ rows: innerRows }) =>
        isEmpty(innerRows) ? (
          <AdminConsoleSectionContent>
            <div className="AdminConsoleList__TableBody--Empty AdminConsoleList__TableBody--Empty--NoData">
              <FormattedMessage id="admin.commonList.noResults" />
            </div>
          </AdminConsoleSectionContent>
        ) : (
          innerRows.map(row => (
            <DataTableRow
              key={getKey(row)}
              value={row}
              getLink={getRowLink}
              renderColumns={renderColumns}
              columnClassName={rowColumnClassName}
            />
          ))
        )
      }
    />
  );
}

const DEFAULT_GET_KEY = property('id');

export function AdminConsoleListDataSection({
  paginationSuffix,
  headerColumns,
  getKey = DEFAULT_GET_KEY,
  getRowLink,
  renderColumns,
  columnClassName,
}) {
  const { rows, totalRows, loading } = useContext(AdminConsoleListContext);

  return (
    <div className="AdminConsoleList__TableContainer">
      {!loading && !isEmpty(rows) && (
        <div className="AdminConsoleList__Pagination">
          <FormattedMessage
            id="admin.commonList.pagination"
            values={{
              count: rows.length,
              total: totalRows,
              entity: <FormattedMessage id={paginationSuffix} />,
            }}
          />
        </div>
      )}
      <AdminConsoleSection>
        <AdminConsoleSectionTableHeader>
          <DataTableHeader
            headerColumns={headerColumns}
            columnClassName={columnClassName}
          />
        </AdminConsoleSectionTableHeader>
        <DataTableBody
          getKey={getKey}
          getRowLink={getRowLink}
          renderColumns={renderColumns}
          rowColumnClassName={columnClassName}
        />
      </AdminConsoleSection>
    </div>
  );
}

const AdminConsoleListContext = createContext({
  sort: { name: '', order: SortOrder.ASC },
  setSort: noop,
  filter: {},
  setFilter: noop,
  hasNext: false,
  loadNext: noop,
  rows: [],
  loading: false,
  loadingPage1: false,
  error: undefined,
  skip: true,
  totalRows: 0,
});

const DEFAULT_GET_VARIABLES = ({ sort, filter }) => ({
  sort,
  filter,
});

export default function AdminConsoleList({
  titleId,
  titleIcon,
  defaultSortColumn,
  shouldSkip = stubFalse,
  query,
  getVariables = DEFAULT_GET_VARIABLES,
  graphqlEntityName,
  graphqlResultTypename,
  getFilterSelector,
  setFilterActionCreator,
  decorateFilter = identity,
  children,
}) {
  const dispatch = useDispatch();
  const store = useStore();

  const [sort, setSort] = useState(makeSort(defaultSortColumn, SortOrder.ASC));
  const [filter, setFilter] = useState(
    getFilterSelector?.(store.getState()) ?? {}
  );

  const debFilter = useDebouncedVariable(filter);
  useEffect(() => {
    if (setFilterActionCreator) {
      dispatch(setFilterActionCreator(debFilter));
    }
  }, [debFilter, dispatch, setFilterActionCreator]);

  const appliedFilter = decorateFilter(debFilter);
  const skip = shouldSkip(appliedFilter);

  const variables = getVariables({ sort, filter: appliedFilter });
  const {
    rows,
    hasNext,
    loadNext,
    loading,
    error,
    onPageReset,
    totalRows,
  } = usePaginatedQuery({
    query,
    variables,
    options: { skip },
  });
  useClearCacheOnVariablesChange({
    query,
    variables,
    graphqlEntityName,
    graphqlResultTypename,
    onReset: onPageReset,
  });

  return (
    <AdminConsoleListContext.Provider
      value={{
        sort,
        setSort,
        filter,
        setFilter,
        hasNext,
        loadNext,
        rows,
        loading,
        error,
        skip,
        totalRows,
      }}
    >
      <AdminConsoleContent titleId={titleId} titleIcon={titleIcon}>
        {children}
      </AdminConsoleContent>
    </AdminConsoleListContext.Provider>
  );
}
