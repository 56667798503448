import { useApolloClient } from '@apollo/client';
import { Col, Row, Tooltip } from 'antd';
import { isEqual, mapValues, noop, property, stubFalse } from 'lodash-es';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, useRouteMatch } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  monitorFiltersFormToGraphQL,
  monitorSortFormToGraphQL,
} from '../app/data/filterConversions';
import { ShipmentStage } from '../app/enums/ShipmentStage';
import { JOBS_COUNTS_QUERY, JOBS_QUERY } from '../app/graphql/jobQueries';
import routes from '../app/routes';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import {
  useCachedProp,
  useDebouncedVariable,
  useMountAndUpdateEffect,
  useMountEffect,
} from '../common/utils/hookUtils';
import { FATypes } from '../components/adapters/fontAwesomeAdapters';
import IconButton from '../components/buttons/IconButton';
import {
  FiltersAndSortContext,
  useFiltersAndSort,
} from '../components/data/FiltersAndSortProvider';
import {
  useClearCacheOnVariablesChange,
  usePaginatedQuery,
} from '../components/data/dataLoaders';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import { FormItemEnumRadioGroup } from '../components/forms/checkable';
import {
  FlexCol,
  TitleWithExtra,
  TwoLines,
} from '../components/layout/layoutElements';
import {
  CollapsibleMapCard,
  CollapsibleMapCardWithToggle,
} from '../components/maps/CollapsibleMapCard';
import { useResponsiveQueries } from '../components/responsive/responsiveQueries';
import { DEFAULT_POLL_REFRESH_INTERVAL } from '../config/constants';
import { cssVariables } from '../styles/cssVariables';
import BaseLoggedPage from '../templates/BaseLoggedPage';
import { msToNumber, pxToNumber } from '../utils/cssUtils';
import {
  FreeTextJobSearchContext,
  useFreeTextJobSearch,
} from '../widgets/FreeTextJobSearch';
import ReleaseShipmentPage from './ReleaseShipmentPage';
import ShipmentDetailPage from './ShipmentDetailPage';
import MonitorFilters from './monitor/MonitorFilters';
import MonitorJobList, {
  MonitorJobListRenderers,
} from './monitor/MonitorJobList';
import MonitorJobMap from './monitor/MonitorJobMap';
import {
  LocationMode,
  MonitorContext,
  MonitorProvider,
} from './monitor/monitorContexts';

const View = {
  MAP: 'map',
  LIST: 'list',
};

const VIEWS = [
  {
    name: View.MAP,
    labelId: 'monitor.view.map',
    icon: 'map',
    type: FATypes.REGULAR,
    isDisabled: shipments =>
      shipments.some(sh => sh.metadata?.blindingInformation?.location),
    disabledTooltipId: 'gdpr.blinded',
  },
  {
    name: View.LIST,
    labelId: 'monitor.view.list',
    icon: 'list-ul',
  },
];

function TitleAndViewSelect({ view, setView }) {
  const { data } = useContext(MonitorContext);
  const allVisibleShipments = Object.values(data)
    .map(({ rows }) => rows)
    .flat();

  const views = useCachedProp(
    VIEWS.map(({ isDisabled = stubFalse, ...rest }) => ({
      disabled: isDisabled(allVisibleShipments),
      ...rest,
    }))
  );
  useEffect(() => {
    // If the current view became disabled, autoselect the first enabled one
    if (views.find(({ name }) => name === view).disabled) {
      setView(views.find(({ disabled }) => !disabled).name);
    }
  }, [setView, view, views]);

  return (
    <TitleWithExtra titleId="monitor.title">
      <Row
        className="MonitorPage-IconButtons hide-sm-and-smaller"
        gutter={pxToNumber(cssVariables.spaceNorm1_5)}
        align="bottom"
      >
        {views.map(({ name, disabled, disabledTooltipId, ...iconProps }) => {
          const active = name === view;

          return (
            <Col key={name}>
              <Tooltip
                title={
                  disabled ? (
                    <FormattedMessage id={disabledTooltipId} />
                  ) : undefined
                }
                placement="topLeft"
              >
                <IconButton
                  active={active}
                  disabled={disabled}
                  size="lg"
                  onClick={() => setView(name)}
                  {...iconProps}
                />
              </Tooltip>
            </Col>
          );
        })}
      </Row>
    </TitleWithExtra>
  );
}

const LOCATION_MODE_VALUES = [LocationMode.origin, LocationMode.destination];
function LocationModeSelect() {
  const { locationMode, setLocationMode } = useContext(MonitorContext);
  return (
    <div className="MonitorLocationModeSelect">
      <TwoLines className="size-6" />
      <div className="MonitorLocationModeSelect-Inner">
        <div className="MonitorLocationModeSelect-Label">
          <FormattedMessage id="monitor.locationMode.label" />:
        </div>
        <FormItemEnumRadioGroup
          intlPrefix="monitor.locationMode"
          intlValues={LOCATION_MODE_VALUES}
          formItemComponentProps={{
            value: locationMode,
            onChange: setLocationMode,
          }}
          noStyle
        />
      </div>
      <TwoLines className="size-6" />
    </div>
  );
}

function ListView() {
  return (
    <FlexCol
      className="Flex1 MonitorPage-Jobs"
      data-subject="job-list"
      data-role="list"
    >
      <LocationModeSelect />
      <MonitorJobList
        renderItem={MonitorJobListRenderers.ROW}
        fixedHeightLayout
      />
    </FlexCol>
  );
}

function MapView() {
  const gutter = pxToNumber(cssVariables.spaceNorm2);
  const innerWidth = pxToNumber(cssVariables.MonitorLeftColumn);
  return (
    <Row
      className="Flex1 MonitorPage-Jobs"
      data-subject="job-list"
      data-role="list"
      gutter={gutter}
    >
      <Col className="FlexCol" style={{ width: `${innerWidth + gutter}px` }}>
        <LocationModeSelect />
        <MonitorJobList
          renderItem={MonitorJobListRenderers.CARD}
          fixedHeightLayout
        />
      </Col>
      <Col className="Flex1">
        <MonitorJobMap />
      </Col>
    </Row>
  );
}

function ContentLarge({ view }) {
  return (
    <>
      <MonitorFilters />
      {view === View.LIST ? <ListView /> : <MapView />}
    </>
  );
}

function ContentMedium({ view }) {
  return (
    <>
      <MonitorJobMap
        renderMap={map => (
          <CollapsibleMapCard expanded={view === View.MAP}>
            {map}
          </CollapsibleMapCard>
        )}
      />
      <MonitorFilters />
      <LocationModeSelect />
      <MonitorJobList
        renderItem={MonitorJobListRenderers.CARD}
        fixedHeightLayout={false}
      />
    </>
  );
}

function ContentSmall({ view, setView }) {
  return (
    <>
      <MonitorJobMap
        renderMap={map => (
          <CollapsibleMapCardWithToggle
            expanded={view === View.MAP}
            setExpanded={expanded => setView(expanded ? View.MAP : View.LIST)}
          >
            {map}
          </CollapsibleMapCardWithToggle>
        )}
      />
      <MonitorFilters />
      <LocationModeSelect />
      <MonitorJobList
        renderItem={MonitorJobListRenderers.CARD}
        fixedHeightLayout={false}
      />
    </>
  );
}

function useJobGroupLoader({ variables: variablesOuter, stage }) {
  const variables = {
    ...variablesOuter,
    filter: {
      ...variablesOuter.filter,
      stage,
    },
  };

  const { onPageReset, ...data } = usePaginatedQuery({
    query: JOBS_QUERY,
    variables,
    pollInterval: undefined,
  });
  useClearCacheOnVariablesChange({
    query: JOBS_QUERY,
    variables,
    graphqlEntityName: 'customerShipments',
    graphqlResultTypename: 'ShipmentsResult',
    onReset: onPageReset,
  });

  return data;
}

// Custom refetch logic to limit refetching of saved and delivered data with the smallest possible impact on the user
function useCustomRefresh({
  counts: countsOuter,
  filter,
  refreshSaved,
  refreshLive,
  refreshDelivered,
  refreshAll,
  isDetailOpen,
}) {
  // The most recent data to be used in interval after asynchronous calls
  // Using normal variables would result in usage of the versions from the time of setting the interval
  const counts = useRef(countsOuter);
  const functions = useRef({
    refreshSaved,
    refreshLive,
    refreshDelivered,
    refreshAll,
  });
  useEffect(() => {
    counts.current = countsOuter;
    functions.current = {
      refreshSaved,
      refreshLive,
      refreshDelivered,
      refreshAll,
    };
  });
  const { errorMessage } = useFlashMessageContext();
  const client = useApolloClient();

  const executeRefresh = useCallback(async () => {
    try {
      const { data: countsData } = await client.query({
        query: JOBS_COUNTS_QUERY,
        variables: { filter },
        fetchPolicy: 'no-cache',
      });
      const {
        saved: savedCount,
        delivered: deliveredCount,
      } = extractGraphqlEntity(countsData);

      const fns = functions.current;
      // "Saved" and "delivered" are refreshed only when their count has changed, "live" and "all" are refreshed every time
      if (fns.refreshSaved && savedCount !== counts.current.saved) {
        fns.refreshSaved();
      }
      if (fns.refreshLive) {
        fns.refreshLive();
      }
      if (fns.refreshDelivered && deliveredCount !== counts.current.delivered) {
        fns.refreshDelivered();
      }
      if (fns.refreshAll) {
        fns.refreshAll();
      }
    } catch (e) {
      errorMessage(e);
    }
  }, [client, errorMessage, filter]);

  useEffect(() => {
    if (isDetailOpen) {
      return noop;
    }
    const interval = setInterval(executeRefresh, DEFAULT_POLL_REFRESH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [executeRefresh, isDetailOpen]);
  useMountAndUpdateEffect(
    {
      onUpdate: ([prevIsDetailOpen]) => {
        // Force refresh when the detail is closed
        if (prevIsDetailOpen && !isDetailOpen) {
          executeRefresh();
        }
      },
    },
    [isDetailOpen]
  );
}

function GroupedMonitorDataLoader({ sort, filters, isDetailOpen, children }) {
  const filter = useMemo(() => monitorFiltersFormToGraphQL(filters), [filters]);
  const variables = { filter, sort };

  const { refresh: refreshSaved, ...saved } = useJobGroupLoader({
    variables,
    stage: ShipmentStage.SAVED,
  });
  const { refresh: refreshLive, ...live } = useJobGroupLoader({
    variables,
    stage: ShipmentStage.LIVE,
  });
  const { refresh: refreshDelivered, ...delivered } = useJobGroupLoader({
    variables,
    stage: ShipmentStage.DELIVERED,
  });

  const data = { saved, live, delivered };

  useCustomRefresh({
    refreshSaved,
    refreshLive,
    refreshDelivered,
    filter,
    counts: mapValues(data, property('totalRows')),
    isDetailOpen,
  });

  return (
    <MonitorProvider data={data} variables={variables}>
      {children}
    </MonitorProvider>
  );
}

function SingleGroupMonitorDataLoader({
  sort,
  freeTextJobSearch,
  isDetailOpen,
  children,
}) {
  const filter = useMemo(() => ({ jobOrBolNumber: freeTextJobSearch.value }), [
    freeTextJobSearch.value,
  ]);
  const variables = { filter, sort };

  const { refresh: refreshAll, ...all } = useJobGroupLoader({ variables });

  const data = { all };

  useCustomRefresh({
    refreshAll,
    filter,
    counts: mapValues(data, property('totalRows')),
    isDetailOpen,
  });

  return (
    <MonitorProvider data={data} variables={variables}>
      {children}
    </MonitorProvider>
  );
}

function MonitorDataLoader({
  sort,
  filters,
  freeTextJobSearch,
  isDetailOpen,
  children,
}) {
  return freeTextJobSearch.value ? (
    <SingleGroupMonitorDataLoader
      sort={sort}
      freeTextJobSearch={freeTextJobSearch}
      isDetailOpen={isDetailOpen}
    >
      {children}
    </SingleGroupMonitorDataLoader>
  ) : (
    <GroupedMonitorDataLoader
      sort={sort}
      filters={filters}
      isDetailOpen={isDetailOpen}
    >
      {children}
    </GroupedMonitorDataLoader>
  );
}

function SetActiveOnClose({ jobNumber }) {
  const { setActiveJob } = useContext(MonitorContext);
  useMountEffect(() => () => setActiveJob(jobNumber));

  return null;
}

export default function MonitorPage() {
  const media = useResponsiveQueries();
  const isLg = media.xxl || media.xl || media.lg;
  const isMd = media.md;
  const isSm = media.sm || media.xs;

  const [view, setView] = useState(VIEWS[0].name);
  const filtersAndSort = useFiltersAndSort();
  const freeTextJobSearch = useFreeTextJobSearch();

  const sort = useDebouncedVariable(
    monitorSortFormToGraphQL(filtersAndSort.sort.values),
    { comparator: isEqual }
  );

  const isDetailOpen = !!useRouteMatch(routes.shipmentDetail);

  return (
    <FiltersAndSortContext.Provider value={filtersAndSort}>
      <FreeTextJobSearchContext.Provider value={freeTextJobSearch}>
        <MonitorDataLoader
          sort={sort}
          filters={filtersAndSort.filters.values}
          freeTextJobSearch={freeTextJobSearch}
          isDetailOpen={isDetailOpen}
        >
          <BaseLoggedPage id="MonitorPage" wrapInScrollbar={isMd || isSm}>
            <TitleAndViewSelect view={view} setView={setView} />
            {isLg && <ContentLarge view={view} setView={setView} />}
            {isMd && <ContentMedium view={view} setView={setView} />}
            {isSm && <ContentSmall view={view} setView={setView} />}
          </BaseLoggedPage>
          <Route path={routes.shipmentDetail}>
            {routeProps => (
              <TransitionGroup>
                {routeProps.match && (
                  <CSSTransition
                    key="detail"
                    timeout={msToNumber(cssVariables.animationDurationMd)}
                    classNames="from-left"
                  >
                    <>
                      <ShipmentDetailPage {...routeProps} />
                      <SetActiveOnClose
                        jobNumber={routeProps.match.params.jobNumber}
                      />
                    </>
                  </CSSTransition>
                )}
              </TransitionGroup>
            )}
          </Route>
          <Route path={routes.releaseShipment}>
            {routeProps => (
              <TransitionGroup>
                {routeProps.match && (
                  <CSSTransition
                    key="release"
                    timeout={msToNumber(cssVariables.animationDurationMd)}
                    classNames="from-left"
                  >
                    <>
                      <ReleaseShipmentPage {...routeProps} />
                      <SetActiveOnClose
                        jobNumber={routeProps.match.params.jobNumber}
                      />
                    </>
                  </CSSTransition>
                )}
              </TransitionGroup>
            )}
          </Route>
        </MonitorDataLoader>
      </FreeTextJobSearchContext.Provider>
    </FiltersAndSortContext.Provider>
  );
}
