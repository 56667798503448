import { gql } from '@apollo/client';

export const SERVICE_ALERTS_QUERY = gql`
  query ServiceAlertsQuery {
    serviceAlerts {
      data {
        title
        description
      }
    }
  }
`;
