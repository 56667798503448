import { Modal } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { cssVariables } from '../../styles/cssVariables';
import { withMergedClass } from '../../utils/reactUtils';

const SimpleDialogIcon = withMergedClass('SimpleDialog-Icon')('div');

export const SimpleDialogMaskStyles = {
  dark: { backgroundColor: `${cssVariables.colorGreyDark2}D9` },
};

export default function SimpleDialog({
  children,
  className,
  title,
  titleId,
  icon,
  visible = true,
  ...rest
}) {
  return (
    <Modal
      className={classNames('SimpleDialog', className)}
      visible={visible}
      title={titleId ? <FormattedMessage id={titleId} /> : title}
      closable={false}
      mask={false}
      maskClosable={false}
      footer={null}
      width="auto"
      {...rest}
    >
      {icon && <SimpleDialogIcon>{icon}</SimpleDialogIcon>}
      {children}
    </Modal>
  );
}

export const SimpleDialogLabel = withMergedClass('SimpleDialog-Label')('div');
