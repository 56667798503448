import { MarkerClusterer } from '@react-google-maps/api';
import { compact, extend, get, isFunction, minBy } from 'lodash-es';
import { createContext, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';

import { triggerEvent } from '../../../utils/htmlUtils';
import { useStateIfMounted } from '../../../utils/reactUtils';

function calculateCluster(items, numStyles) {
  const numItems = items.length;
  const index = Math.min(Math.ceil(Math.log10(numItems)), numStyles);
  const text = `${numItems}`;
  return { index, text };
}

function prepareGetTooltipParams({ cluster, parent }) {
  const { markers } = cluster;
  const element = cluster.clusterIcon.div;

  if (!element || markers.length < cluster.minClusterSize) {
    return null;
  }

  const offsetRect = parent?.offsetParent?.getBoundingClientRect();
  const rect = cluster.clusterIcon.div.getBoundingClientRect();
  const rectElement = (
    <div
      className="Cluster__FakeTooltipTrigger"
      style={{
        left: rect.left - (offsetRect?.left || 0),
        top: rect.top - (offsetRect?.top || 0),
        width: rect.width,
        height: rect.height,
      }}
      onClick={() => triggerEvent(element, 'click')}
    />
  );
  return { markers, rect, rectElement };
}

export const GoogleMapClustererContext = createContext();
export function GoogleMapClustererProvider({
  children,
  getTooltip,
  tooltipParent: tooltipParentOuter,
  calculator = calculateCluster,
  styles,
  enable,
  includeMarkerInClustering,
}) {
  const [tooltips, setTooltips] = useStateIfMounted();
  const tooltipParent = tooltipParentOuter || document.body;
  const clustererPatchApplied = useRef(false);

  const patchClustererOnce = useCallback(
    clusterer => {
      // We need to customize clusterer to only include some markers, but it's not possible in its options,
      // therefore we have to patch the object manually
      if (
        !clustererPatchApplied.current &&
        isFunction(includeMarkerInClustering)
      ) {
        // This class is not exported, so we get it using this hack
        const Cluster = get(clusterer, 'clusters.0.constructor');
        if (!Cluster) {
          return;
        }
        /* eslint-disable react/no-this-in-sfc */
        extend(clusterer, {
          // Based on: https://github.com/JustFly1984/react-google-maps-api/blob/develop/packages/react-google-maps-api-marker-clusterer/src/Clusterer.tsx#L588
          addToClosestCluster(marker) {
            const doInclude = includeMarkerInClustering(marker);

            const distances = doInclude
              ? this.clusters.map(cluster => {
                  const center = cluster.getCenter();
                  const position = marker.getPosition();
                  const distance =
                    center &&
                    position &&
                    this.distanceBetweenPoints(center, position);

                  return distance && { cluster, distance };
                })
              : [];
            const closestCluster = minBy(compact(distances), 'distance')
              ?.cluster;

            if (
              closestCluster &&
              closestCluster.isMarkerInClusterBounds(marker)
            ) {
              closestCluster.addMarker(marker);
            } else {
              const cluster = new Cluster(this);
              cluster.addMarker(marker);
              this.clusters.push(cluster);
            }
          },
        });
        clustererPatchApplied.current = true;
        clusterer.repaint();
      }
    },
    [includeMarkerInClustering]
  );
  /* eslint-enable react/no-this-in-sfc */

  const onClusteringEnd = useCallback(
    clusterer => {
      patchClustererOnce(clusterer);
      if (!getTooltip) {
        return;
      }
      requestAnimationFrame(() => {
        setTooltips(
          compact(
            clusterer.clusters.map(cluster => {
              const params = prepareGetTooltipParams({
                cluster,
                parent: tooltipParent,
              });
              if (!params) {
                return null;
              }
              return getTooltip(params);
            })
          )
        );
      });
    },
    [getTooltip, patchClustererOnce, setTooltips, tooltipParent]
  );

  return enable ? (
    <>
      {createPortal(<>{tooltips}</>, tooltipParent)}
      <MarkerClusterer
        averageCenter
        minimumClusterSize={2}
        calculator={calculator}
        styles={styles}
        onClusteringEnd={onClusteringEnd}
      >
        {clusterer => (
          <GoogleMapClustererContext.Provider value={clusterer}>
            {children}
          </GoogleMapClustererContext.Provider>
        )}
      </MarkerClusterer>
    </>
  ) : (
    children
  );
}
