import { Dropdown, Menu, Popover } from 'antd';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { createContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useNodeInnerText } from '../../common/utils/stringUtils';
import { joinReactElements } from '../../utils/reactUtils';
import Whitespace from '../Whitespace';
import { FAIcon } from '../adapters/fontAwesomeAdapters';
import { VerticalDivider } from '../layout/layoutElements';

function renderLink(link) {
  if (link.href) {
    return (
      <a
        key={link.textId || link.text}
        href={link.href}
        target="__blank"
        {...link.props}
      >
        {link.textId ? <FormattedMessage id={link.textId} /> : link.text}
      </a>
    );
  }
  if (link.to) {
    return (
      <Link key={link.textId || link.text} to={link.to} {...link.props}>
        {link.textId ? <FormattedMessage id={link.textId} /> : link.text}
      </Link>
    );
  }
  return link;
}

export function LinksRow({ links, className, ...rest }) {
  return (
    <div className={classNames('LinksRow', className)} {...rest}>
      {joinReactElements(links.map(renderLink), i => (
        <VerticalDivider key={i} />
      ))}
    </div>
  );
}

export const PopupContext = createContext({ isOpen: false, setOpen: noop });

export function Popup({
  labelId,
  label,
  trigger,
  placement,
  titleId,
  title,
  customOpenIcon,
  customClosedIcon,
  noIcon,
  children,
  className,
  disabled,
  ...rest
}) {
  const [isOpen, setOpen] = useState();
  const { ref, text } = useNodeInnerText();

  return (
    <PopupContext.Provider value={{ isOpen, setOpen }}>
      <Popover
        className={classNames('Popup-Label', className)}
        title={titleId ? <FormattedMessage id={titleId} /> : title}
        trigger={disabled ? 'none' : trigger}
        placement={placement}
        content={children}
        visible={isOpen}
        onVisibleChange={setOpen}
        {...rest}
      >
        <div ref={ref} title={text}>
          {labelId ? <FormattedMessage id={labelId} /> : label}
          {!noIcon && (
            <>
              <Whitespace />
              {isOpen
                ? customOpenIcon || <FAIcon icon="chevron-up" />
                : customClosedIcon || <FAIcon icon="chevron-down" />}
            </>
          )}
        </div>
      </Popover>
    </PopupContext.Provider>
  );
}

export function DropdownMenu({
  labelId,
  label,
  trigger,
  placement = 'bottomRight',
  className,
  overlayClassName,
  noArrow,
  children,
  ...rest
}) {
  const overlay = <Menu>{children}</Menu>;
  return (
    <Dropdown
      className={classNames('DropdownMenu-Label', className)}
      overlayClassName={classNames('DropdownMenu-Overlay', overlayClassName)}
      trigger={trigger}
      placement={placement}
      overlay={overlay}
      {...rest}
    >
      <div>
        {labelId ? <FormattedMessage id={labelId} /> : label}
        {!noArrow && (
          <>
            <Whitespace />
            <FAIcon icon="caret-down" />
          </>
        )}
      </div>
    </Dropdown>
  );
}
