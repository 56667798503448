import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { startsWith } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt, generatePath } from 'react-router';

import routes from '../app/routes';
import { useMountEffect } from '../common/utils/hookUtils';
import { FAIcon, FATypes } from '../components/adapters/fontAwesomeAdapters';
import { DataStateIndicatorGuard } from '../components/data/dataStateHandlers';
import { DialogsParentContext } from '../components/dialogs/DialogsParentContext';
import ModalOverlay from '../components/dialogs/ModalOverlay';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';
import { FormSanitizationProvider } from '../components/forms/FormSanitizationContext';
import { DynamicFormDependenciesProvider } from '../components/forms/dynamic/dynamicFormDependencies';
import {
  StepsWithContent,
  isFirstStep,
  isLastStep,
} from '../components/nav/StepsWithContent';
import { InlineLink } from '../components/typography';
import {
  EditShipmentSubmitDialog,
  useEditShipmentSubmit,
} from '../dialogs/shipments/editShipmentDialogs';
import NewShipmentFormSchemaProvider from '../forms/shipments/newShipment/NewShipmentFormSchemaProvider';
import { SavedShipmentProvider } from '../forms/shipments/newShipment/SavedShipmentProvider';
import { NEW_SHIPMENT_STEPS } from '../forms/shipments/newShipment/newShipmentConstants';
import {
  NewShipmentBottomControls,
  NewShipmentFormButtons,
} from '../forms/shipments/newShipment/newShipmentElements';
import { isSavedShipmentModeActive } from '../forms/shipments/newShipment/savedShipmentsUtils';
import useNewShipmentAsyncConfig from '../forms/shipments/newShipment/useNewShipmentAsyncConfig';
import useNewShipmentFormState from '../forms/shipments/newShipment/useNewShipmentFormState';
import useNewShipmentSteps from '../forms/shipments/newShipment/useNewShipmentSteps';
import useSubmitNewShipmentStep from '../forms/shipments/newShipment/useSubmitNewShipmentStep';
import ShipmentTemplatesCard from '../forms/shipments/shipmentTemplates/ShipmentTemplatesCard';
import { useAccount } from '../hooks/data/auth';
import ModalPage from '../templates/ModalPage';
import { PageExitDialogType } from './nav/PageExitDialog';

function EditShipmentPageWrapper({ header, children }) {
  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <ModalPage
      className={classNames('EditShipmentPage height-extending-error', {
        'has-dialog': isDialogOpen,
      })}
      header={header}
      wrapInScrollbar
    >
      <DialogsParentContext.Provider value={{ isDialogOpen, setDialogOpen }}>
        {children}
      </DialogsParentContext.Provider>
    </ModalPage>
  );
}

function EditShipmentExitPrompt({ isConfirming }) {
  return (
    <Prompt
      message={({ pathname }) =>
        startsWith(pathname, routes.editShipment)
          ? true
          : PageExitDialogType.CANCEL_EDIT_SHIPMENT
      }
      when={!isConfirming}
    />
  );
}

function EditShipmentProviders({ values, account, unitSystem, children }) {
  return (
    <NewShipmentFormSchemaProvider account={account}>
      <DynamicFormDependenciesProvider values={values}>
        <SavedShipmentProvider>
          <OverrideSettingsContextProvider unitSystem={unitSystem}>
            <FormSanitizationProvider>{children}</FormSanitizationProvider>
          </OverrideSettingsContextProvider>
        </SavedShipmentProvider>
      </DynamicFormDependenciesProvider>
    </NewShipmentFormSchemaProvider>
  );
}

function ExitLink({ jobNumber, className }) {
  return (
    <InlineLink
      className={classNames('EditShipment-CancelShipment', className)}
      to={generatePath(routes.releaseShipment, { jobNumber })}
    >
      <FormattedMessage id="editShipment.exit" />
    </InlineLink>
  );
}

function EditShipmentHeader({ jobNumber, children }) {
  return (
    <Row align="middle" justify="space-between">
      <Col>
        <Typography.Title level={2}>
          <FormattedMessage id="editShipment.title" />
        </Typography.Title>
        <ExitLink jobNumber={jobNumber} className="hide-md-and-smaller" />
      </Col>
      <Col className="EditShipmentPage__TopButtons">{children}</Col>
    </Row>
  );
}

function useInitialFormValues({
  forms,
  setAccount,
  setUnitSystem,
  refreshValues,
  shipmentForm,
}) {
  useMountEffect(() => {
    setAccount(shipmentForm.account);
    forms[0].form.setFieldsValue(shipmentForm.origin);
    forms[1].form.setFieldsValue(shipmentForm.destination);
    forms[2].form.setFieldsValue(shipmentForm.serviceInformation);
    forms[3].form.setFieldsValue(shipmentForm.packageList);
    forms.forEach(({ formRef }) => {
      if (formRef.current) {
        formRef.current.forceUpdate();
      }
    });
    setUnitSystem(shipmentForm.unitSystem);
    refreshValues();
  });

  // Account in the detail doesn't contain all information, so we will enrich it
  const account = useAccount(shipmentForm.account.number);
  useEffect(() => {
    setAccount(account);
    refreshValues();
    // Only listen to account change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
}

function EditShipmentPageContent({ shipmentForm, jobNumber }) {
  const BoundExitLink = useMemo(
    () => props => <ExitLink {...props} jobNumber={jobNumber} />,
    [jobNumber]
  );

  const {
    forms,
    refreshValues,
    account,
    setAccount,
    unitSystem,
    setUnitSystem,
    values,
    extractValues,
  } = useNewShipmentFormState();

  useInitialFormValues({
    forms,
    setAccount,
    setUnitSystem,
    refreshValues,
    shipmentForm,
  });

  const {
    submit,
    dialogProps: submitDialogProps,
    isConfirming,
  } = useEditShipmentSubmit({
    jobNumber,
  });

  const { stepsProps, currentIndex, goToNext, goToPrev } = useNewShipmentSteps({
    forms,
    refreshValues,
    steps: NEW_SHIPMENT_STEPS,
    disableUnvisitedSteps: false,
  });

  const {
    accountDetailResult,
    isQuoteEnabled: isQuoteEnabledConfig,
  } = useNewShipmentAsyncConfig({
    account,
  });
  const isQuoteEnabled = isQuoteEnabledConfig && !isSavedShipmentModeActive;

  const submitStep = useSubmitNewShipmentStep({
    forms,
    stepsProps,
    currentIndex,
    submit,
    extractValues,
    isQuoteEnabled,
    goToNext,
    steps: NEW_SHIPMENT_STEPS,
  });

  return (
    <EditShipmentProviders
      account={account}
      unitSystem={unitSystem}
      values={values}
    >
      <EditShipmentPageWrapper
        header={
          <EditShipmentHeader jobNumber={jobNumber}>
            <DataStateIndicatorGuard queryResult={accountDetailResult}>
              {() => (
                <NewShipmentFormButtons
                  onSubmit={submitStep}
                  onBack={goToPrev}
                  isFirstStep={isFirstStep(stepsProps)}
                  isLastStep={isLastStep(stepsProps)}
                  isQuoteEnabled={isQuoteEnabled}
                  buttonProps={{ size: 'large' }}
                  nextLabelId="buttons.saveContinue"
                  primaryButtonIcon={
                    <FAIcon
                      icon="arrow-alt-circle-right"
                      type={FATypes.REGULAR}
                      className="icon-22"
                    />
                  }
                />
              )}
            </DataStateIndicatorGuard>
          </EditShipmentHeader>
        }
      >
        <EditShipmentExitPrompt isConfirming={isConfirming} />
        <div className="spaces-vert-norm1_5">
          <ShipmentTemplatesCard forms={forms} values={values} disabled />
          <StepsWithContent
            {...stepsProps}
            className="spaces-vert-norm1_5"
            stepsClassName="labels-in-middle"
            segmentedControlClassName="margin-hor-norm"
            clickable
          />
        </div>
        <DataStateIndicatorGuard queryResult={accountDetailResult}>
          {() => (
            <>
              <NewShipmentBottomControls
                submitStep={submitStep}
                stepsProps={stepsProps}
                isQuoteEnabled={isQuoteEnabled}
                cancelComponent={BoundExitLink}
              />
              <EditShipmentSubmitDialog
                {...submitDialogProps}
                isQuoteEnabled={isQuoteEnabled}
              />
            </>
          )}
        </DataStateIndicatorGuard>
      </EditShipmentPageWrapper>
    </EditShipmentProviders>
  );
}

export default function EditShipmentPage({ match, shipmentForm, levelBoost }) {
  const { jobNumber } = match.params;

  return (
    <ModalOverlay levelBoost={levelBoost}>
      <EditShipmentPageContent
        jobNumber={jobNumber}
        shipmentForm={shipmentForm}
      />
    </ModalOverlay>
  );
}
