import { Button, Col, Form, Row } from 'antd';
import { get } from 'lodash-es';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  FAIcon,
  FATypes,
} from '../../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../../components/buttons/IconButton';
import ListEditDrawer from '../../../components/drawers/ListEditDrawer';
import FormList from '../../../components/forms/FormList';
import {
  FormItemFieldRegistration,
  FormItemInputNumber,
  FormItemInputText,
} from '../../../components/forms/basicFormElements';
import { useFormListCallbacks } from '../../../components/forms/formDecorators';
import { EnsureInitialFormListItem } from '../../../components/forms/formHelpers';
import { useFormContext } from '../../../components/forms/forms';
import { withIntermediateSubformState } from '../../../components/forms/subformDecorators';
import {
  FlexCol,
  TwoLines,
  TwoLinesSeparator,
} from '../../../components/layout/layoutElements';
import { InlineLink, LabelWithValue } from '../../../components/typography';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { useShipmentMode } from '../shipmentModes';
import { PackageListCardSection } from './packageListCommon';

function SmOrLgCol({ sm, lg, ...rest }) {
  return <Col xl={lg} lg={lg} md={sm} sm={sm} xs={sm} {...rest} />;
}

function PieceEditRow({ fields, index, add, remove, move, children }) {
  const { values } = useFormContext();
  const { addAfter, removeThis } = useFormListCallbacks({
    fields,
    index,
    add,
    remove,
    move,
  });
  const currentValue = get(values, ['pieces', index]);

  return (
    <Row align="middle" gutter={pxToNumber(cssVariables.spaceNorm2)}>
      <Col>
        <FlexCol
          align="center"
          justify="space-around"
          className="PieceList-Controls"
        >
          <IconButton
            icon="trash-alt"
            onClick={removeThis}
            titleId="buttons.delete"
            data-subject="piece-list"
            data-action="delete"
          />
          <IconButton
            icon="clone"
            onClick={() => addAfter(currentValue)}
            titleId="buttons.copy"
            data-subject="piece-list"
            data-action="copy"
          />
          <IconButton
            icon="plus-square"
            onClick={addAfter}
            titleId="buttons.add"
            data-subject="piece-list"
            data-action="add-after"
          />
        </FlexCol>
      </Col>
      <Col className="Flex1 PieceList-Envelope">
        <Row
          align="top"
          gutter={pxToNumber(cssVariables.spaceNorm2)}
          className="no-margin-form-items"
        >
          {children}
        </Row>
      </Col>
    </Row>
  );
}

function AviationPieceEdit({ fields, add, remove, move, name, index }) {
  return (
    <PieceEditRow
      fields={fields}
      index={index}
      add={add}
      remove={remove}
      move={move}
    >
      <SmOrLgCol lg={8} sm={14}>
        <FormItemInputText
          name={[name, 'partNumber']}
          labelId="book.newShipment.label.partNumber"
          schemaName="packages.pieces.partNumber"
        />
      </SmOrLgCol>
      <SmOrLgCol lg={4} sm={10}>
        <FormItemInputNumber
          name={[name, 'quantity']}
          labelId="book.newShipment.label.quantity"
          schemaName="packages.pieces.quantity"
        />
      </SmOrLgCol>
      <SmOrLgCol lg={12} sm={24}>
        <FormItemInputText
          name={[name, 'description']}
          labelId="book.newShipment.label.description"
          schemaName="packages.pieces.description"
        />
      </SmOrLgCol>
    </PieceEditRow>
  );
}

const PIECE_EDIT_COMPONENTS = {
  aviation: AviationPieceEdit,
};
function PieceListEditingMode({ saveChanges, stopEditing }) {
  const { piecesType } = useShipmentMode();
  const PieceComponent = PIECE_EDIT_COMPONENTS[piecesType];
  const renderList = (fields, { add, remove, move }) => (
    <div className="PieceList-EditingList">
      <EnsureInitialFormListItem fields={fields} add={add} value={{}} />
      {(fields || []).map(({ key, name }, index) => (
        <PieceComponent {...{ fields, add, remove, move, key, name, index }} />
      ))}
    </div>
  );

  return (
    <div>
      <FormList name="pieces">{renderList}</FormList>
      <div className="NewShipmentBottomControls">
        <Button
          size="small"
          onClick={stopEditing}
          data-subject="piece-list"
          data-action="cancel"
        >
          <FormattedMessage id="buttons.cancel" />
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={saveChanges}
          data-subject="piece-list"
          data-action="save"
        >
          <FormattedMessage id="buttons.save" />
          <FAIcon icon="save" className="icon-21" />
        </Button>
      </div>
      <TwoLines className="size-10" />
    </div>
  );
}

function PiecePreviewRow({ index, children, ...rest }) {
  return (
    <>
      {index === 0 && <TwoLinesSeparator className="wide" />}
      <div className="PieceList-PreviewRow">
        <div className="number-in-circle">{index + 1}</div>
        <Row className="Flex1" align="top" {...rest}>
          {children}
        </Row>
      </div>
      <TwoLinesSeparator />
    </>
  );
}

function AviationPiecePreview({ piece, index, ...rest }) {
  return (
    <PiecePreviewRow index={index} {...rest}>
      <SmOrLgCol lg={8} sm={14}>
        <LabelWithValue
          labelSize="full"
          labelId="book.newShipment.label.partNumber"
          text={get(piece, 'partNumber')}
        />
      </SmOrLgCol>
      <SmOrLgCol lg={4} sm={10}>
        <LabelWithValue
          labelSize="full"
          labelId="book.newShipment.label.quantity"
          text={get(piece, 'quantity')}
        />
      </SmOrLgCol>
      <SmOrLgCol lg={12} sm={24}>
        <LabelWithValue
          labelSize="full"
          labelId="book.newShipment.label.description"
          text={get(piece, 'description')}
        />
      </SmOrLgCol>
    </PiecePreviewRow>
  );
}

const PIECE_PREVIEW_COMPONENTS = {
  aviation: AviationPiecePreview,
};
function PieceListPreviewMode({
  values: nonEditableValues,
  startEditing,
  noEdit,
}) {
  const { values } = useFormContext();
  const { piecesType } = useShipmentMode();
  const PieceComponent = PIECE_PREVIEW_COMPONENTS[piecesType];
  const pieces = nonEditableValues || values?.pieces || [];

  return (
    <div>
      {!noEdit && (
        <div className="PieceList-Header">
          <LabelWithValue
            className="with-colon"
            labelSize="md"
            size="lg"
            labelId="book.newShipment.packages.pieceList.dialog.numberOfItems"
            text={`${values?.pieces?.length || 0}`}
          />
          <IconButton icon="pen-square" onClick={startEditing} />
        </div>
      )}
      <div className="PieceList-PreviewRows">
        {pieces.map((piece, i) => (
          <PieceComponent
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            index={i}
            piece={piece}
          />
        ))}
      </div>
    </div>
  );
}

const PieceListListingInner = withIntermediateSubformState({
  renderEditing: props => <PieceListEditingMode {...props} />,
  renderPreview: props => <PieceListPreviewMode {...props} />,
});
function PieceListListing({ value, onChange: onChangeOuter, ...rest }) {
  const subformValue = { pieces: value };
  const onChange = useCallback(val => onChangeOuter(val.pieces), [
    onChangeOuter,
  ]);

  return (
    <PieceListListingInner value={subformValue} onChange={onChange} {...rest} />
  );
}

function PieceListFormWrapper(props) {
  return (
    <Form.Item name="pieces" noStyle>
      <PieceListListing {...props} />
    </Form.Item>
  );
}

function PieceListDrawer({ close, isOpen, noEdit, editing, ...rest }) {
  const { hasPieces, piecesType } = useShipmentMode();
  if (!hasPieces) {
    return null;
  }

  return (
    <ListEditDrawer
      titleId={`book.newShipment.packages.pieceList.${piecesType}.dialog.title`}
      isOpen={isOpen}
      close={close}
      className="PieceListDrawer"
    >
      <PieceListFormWrapper
        noEdit={noEdit}
        editing={noEdit ? false : editing}
        {...rest}
      />
    </ListEditDrawer>
  );
}

export function PackageListPieceListSection({
  open,
  close,
  isOpen,
  setEditing,
  ...rest
}) {
  const { hasPieces, piecesType } = useShipmentMode();
  if (!hasPieces) {
    return null;
  }

  const openAndStartEditing = () => {
    open();
    setEditing(true);
  };
  return (
    <div className="spaces-vert-md">
      <FormItemFieldRegistration name="pieces" />
      <div className="TwoLines size-10" />
      <PackageListCardSection noTopLines>
        <Button
          type="default"
          size="large"
          className="PieceList-Button"
          onClick={openAndStartEditing}
          data-subject="piece-list"
          data-action="open"
        >
          <FormattedMessage
            id={`book.newShipment.packages.button.pieceList.${piecesType}.edit`}
          />
          <FAIcon
            icon="external-link-square-alt"
            type={FATypes.SOLID}
            className="icon-16"
          />
        </Button>
      </PackageListCardSection>
      <PieceListDrawer
        isOpen={isOpen}
        close={close}
        setEditing={setEditing}
        {...rest}
      />
    </div>
  );
}

export function PackageListPieceListPreviewButton({
  open,
  close,
  isOpen,
  values,
  ...rest
}) {
  const { hasPieces, piecesType } = useShipmentMode();
  if (!hasPieces || !values) {
    return null;
  }

  return (
    <>
      <InlineLink onClick={open} className="PieceList-Button">
        <FormattedMessage
          id={`book.newShipment.packages.button.pieceList.${piecesType}.view`}
        />
        <FAIcon
          icon="external-link-square-alt"
          type={FATypes.SOLID}
          className="icon-16"
        />
      </InlineLink>
      <PieceListDrawer
        values={values}
        noEdit
        isOpen={isOpen}
        close={close}
        {...rest}
      />
    </>
  );
}
